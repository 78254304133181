import React from "react";
import { auth } from "../../firebase/firebase.utils";
import axiosConfig from "../../axios/axios";

class PocPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayText: "Tetsing display text"
        };
    }
    async componentDidMount(props) {}
    run = async () => {
        try {
            const token = await auth.currentUser.getIdToken();
            console.log("token", token);

            const result = await axiosConfig.get("/presets", {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log(result);
        } catch (err) {
            console.log(err);
            this.setState({ displayText: "Error" });
        }
    };
    render() {
        return (
            <div className="container my-5 py-4">
                <h1>POC page</h1>
                <button className="btn btn-primary" onClick={this.run}>
                    Test
                </button>{" "}
                <br />
                <code>{this.state.displayText}</code>
            </div>
        );
    }
}

export default PocPage;
