import React from "react";
import { Link } from "react-router-dom";

class PresetInfo extends React.Component {
    constructor() {
        super();
        this.state = {};
    }
    render = () => {
        const {
            presetId,
            name,
            brand,
            model,
            numOfSaves,
            savedAt,
            lastUpdated,
            visibility
        } = this.props.presetInfo;
        return (
            <div>
                {/* This is a preset info block */}
                <div className="d-flex flex-wrap justify-content-between align-items-center ">
                    <div className="d-flex align-items-end">
                        <Link to={`/presets/${presetId}`}>
                            <div className="text-size-150">
                                <strong>{name} </strong>
                            </div>
                        </Link>
                        <div className="ms-2 opacity-50">
                            {visibility === "PUBLIC" ? (
                                <i className="fa-solid fa-people-group"></i>
                            ) : visibility === "PRIVATE" ? (
                                <i className="fa-solid fa-lock"></i>
                            ) : visibility === "UNLISTED" ? (
                                <i className="fa-solid fa-eye-slash"></i>
                            ) : null}
                        </div>
                    </div>
                    <div className="text-size-150">
                        <i className="fa-solid fa-bookmark"></i>{" "}
                        <span className="ms-2">{numOfSaves}</span>
                    </div>
                </div>
                <div className="d-sm-flex justify-content-between align-items-center ">
                    <div>
                        {brand} - {model}
                    </div>
                    {savedAt ? (
                        <div>Saved on {new Date(savedAt).toDateString()}</div>
                    ) : (
                        <div>
                            Last updated on{" "}
                            {new Date(lastUpdated).toDateString()}
                        </div>
                    )}
                </div>
                <hr />
            </div>
        );
    };
}

export default PresetInfo;
