import React from "react";

import { auth } from "../../firebase/firebase.utils";

import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axiosConfig from "../../axios/axios";
import Spinner from "../../components/spinner/spinner.component";

import SchemaBuilder from "../../components/schema-builder/schema-builder.component";

import TagsInput from "../../components/tags-input/tags-input.component";
import TextareaAutosize from "react-textarea-autosize";
import InputSelect from "../../components/input-select/input-select.component";

import {
    APP_NAME,
    PRESET_TYPES,
    VISIBILITY_OPTIONS
} from "../../utils/constants.utils";

import SchemaViewer from "../../components/schema-viewer/schema-viewer.component";
import PresetViewer from "../../components/preset-viewer/preset-viewer.component";
import ScrollToTop from "../../components/scroll-to-top/scroll-to-top.component";

// import {dataLayerPush} from "../../utils/tagging.utils"

const initialState = {
    name: "",
    description: "",
    fileStr: "",
    fileJson: {},
    schema: null,
    presetTags: "",
    tags: [],
    demoLink: "",
    visibility: VISIBILITY_OPTIONS[0],
    submitting: false,
    errorMessage: "",
    presetType: PRESET_TYPES.schema,
    step: 0,
    submitAsSchema: false
};

const steps = [
    { name: "Choose Preset Type" },
    { name: "Create Preset" },
    { name: "Enter Details and Share" }
];

const totalSteps = steps.length;
const lastStep = totalSteps - 1;

class PresetsCreatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        document.title = APP_NAME + " | Create";
    }

    updateInput = (e) => {
        const target = e.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    updateTags = (newTags) => {
        if (newTags.length > this.state.tags.length) {
            let lastItem = newTags.pop();
            lastItem = lastItem.replace(/[^0-9a-zA-Z\s]+/g, "").toLowerCase();
            if (lastItem) newTags.push(lastItem);
            this.setState({ tags: newTags });
        } else {
            this.setState({ tags: newTags });
        }
    };

    handleSubmission = (e) => {
        e.preventDefault();
        this.setState({ submitting: true }, async () => {
            try {
                if (!this.props.authState.isSignedIn)
                    alert(
                        "You must be signed in to share, please sign up or sign in."
                    );
                else {
                    const bodyParams = {
                        name: this.state.name,
                        description: this.state.description || "",
                        ownerId: this.props.authState.user.uid,
                        tags: this.state.tags,
                        demoLink: this.state.demoLink,
                        presetType: this.state.presetType,
                        visibility: this.state.visibility
                    };

                    if (this.state.presetType === PRESET_TYPES.thrii) {
                        bodyParams.thrl6p = this.state.fileJson;
                        bodyParams.thrl6pRaw = this.state.fileStr;
                        bodyParams.brand = "Yamaha";
                        bodyParams.model = "THR-ii";
                    } else if (this.state.presetType === PRESET_TYPES.schema) {
                        bodyParams.schema = this.schema;
                        bodyParams.brand = this.schema.brand;
                        bodyParams.model = this.schema.model;
                        bodyParams.isCustom = this.schema.isCustom;
                        bodyParams.submitAsSchema = this.state.submitAsSchema;
                    }

                    const authToken = await auth.currentUser.getIdToken();
                    const config = {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    };
                    const res = await axiosConfig.post(
                        "/presets",
                        bodyParams,
                        config
                    );

                    this.props.history.push("/presets/" + res.data.presetId);
                }
            } catch (err) {
                let errorMsg = "";
                if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    errorMsg = err.response.data.message;
                }
                this.setState({ submitting: false, errorMessage: errorMsg });
                console.log(err);
                alert("Something went wrong, please refresh and try again");
            }
        });
    };
    handleFileUpload = (e) => {
        const fileReader = new FileReader();
        try {
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = (e) => {
                try {
                    const fileJson = JSON.parse(e.target.result);
                    const name = fileJson.data.meta.name;
                    // if (!name) throw new Error("Invalid file.");
                    this.setState({
                        fileStr: e.target.result,
                        fileJson: fileJson,
                        name: name,
                        tags: [
                            ...this.state.tags,
                            name.replace(/[^0-9a-zA-Z\s]+/g, "").toLowerCase()
                        ]
                    });
                } catch (err) {
                    console.log(err);
                    alert("There is something wrong with the file");
                }
            };
        } catch (err) {
            console.log(err);
        }
    };
    onSchemaUpdate = (schema) => {
        this.schema = schema;
    };

    changeStepTo = (stepIndex) => {
        this.setState({ step: stepIndex });
    };
    incrementStep = () => {
        if (this.state.step < lastStep) {
            let userConfirm = true;

            if (this.state.step === 1) {
                // If at step 1 and continue to next step
                userConfirm = window.confirm(
                    "Please confirm your preset, after this step, editing can only be done if you build the preset from scratch."
                );
            }
            if (userConfirm) this.setState({ step: this.state.step + 1 });
        }
    };
    decrementStep = () => {
        if (this.state.step > 0) {
            let userConfirm = true;
            if (this.state.step === 2) {
                // If at step 2 and trying to go back
                userConfirm = window.confirm(
                    "You will need to configure the preset from scratch, continue?"
                );
            }
            if (userConfirm) this.setState({ step: this.state.step - 1 });
        }
    };

    handlePresetTypeSelection = (e) => {
        this.setState({ presetType: e.target.value });
    };

    onVisibilityOptionChange = (e) => {
        this.setState({ visibility: e.target.value });
    };

    renderStepControls = () => {
        return (
            <div className="d-flex justify-content-between">
                {this.state.step === 0 && <div></div>}
                {this.state.step !== 0 && (
                    <button
                        className="btn btn-outline-primary"
                        onClick={this.decrementStep}
                        disabled={!this.props.authState.isSignedIn}
                    >
                        Previous step
                    </button>
                )}
                {this.state.step !== totalSteps - 1 && (
                    <button
                        className="btn btn-primary"
                        disabled={!this.props.authState.isSignedIn}
                        onClick={this.incrementStep}
                    >
                        Next step{" "}
                        {!this.props.authState.isSignedIn &&
                            "- Sign in to continue"}{" "}
                    </button>
                )}
                {this.state.step === lastStep && (
                    <div className="text-end">
                        {this.state.errorMessage && (
                            <span className="text-danger mx-3">
                                {this.state.errorMessage}
                            </span>
                        )}
                        {this.state.submitting ? (
                            <Spinner className="ms-3" />
                        ) : (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={!this.props.authState.isSignedIn}
                                data-tag-this="button_click"
                                data-tag-details="Click on submit to share preset"
                                onClick={this.handleSubmission}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="container page-wrapper">
                <h1 className="my-3">Create a new tone profile</h1>

                <ul className="nav nav-pills nav-justified">
                    {steps.map((step, stepIndex) => {
                        return (
                            <li className="nav-item px-1" key={stepIndex}>
                                <button
                                    className={`nav-link ${
                                        this.state.step >= stepIndex
                                            ? "active"
                                            : "active opacity-25"
                                    }`}
                                    href="#"
                                    // onClick={() => this.changeStepTo(stepIndex)}
                                ></button>
                                <div className="text-start mt-2">
                                    {this.state.step > stepIndex ? (
                                        <i className="fa-solid fa-circle-check text-success me-1"></i>
                                    ) : null}
                                    {this.state.step === stepIndex ? (
                                        <i className="fa-solid fa-pen-ruler text-secondary me-1"></i>
                                    ) : null}
                                    {step.name}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <hr />

                <h2 className="mt-4">{steps[this.state.step].name}</h2>
                {this.state.step === 0 && (
                    <div className="my-3">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                value={PRESET_TYPES.schema}
                                checked={
                                    this.state.presetType ===
                                    PRESET_TYPES.schema
                                }
                                onChange={this.handlePresetTypeSelection}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault1"
                            >
                                Create a visual preset
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault2"
                                value={PRESET_TYPES.thrii}
                                checked={
                                    this.state.presetType === PRESET_TYPES.thrii
                                }
                                onChange={this.handlePresetTypeSelection}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="flexRadioDefault2"
                            >
                                Upload a THRii preset file (.thrl6p)
                            </label>
                        </div>
                    </div>
                )}

                {this.state.step === 1 && (
                    <div>
                        <ScrollToTop />
                        {this.state.presetType === PRESET_TYPES.schema && (
                            <SchemaBuilder
                                onSchemaUpdate={this.onSchemaUpdate}
                                user={this.props.user}
                                createSchemaMode={false}
                            />
                        )}
                        {this.state.presetType === PRESET_TYPES.thrii && (
                            <div className="mb-3">
                                <label
                                    htmlFor="formFile"
                                    className="form-label"
                                >
                                    Upload a file
                                </label>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    onChange={this.handleFileUpload}
                                    required
                                />
                            </div>
                        )}
                    </div>
                )}

                {this.state.step === 2 && (
                    <div>
                        <ScrollToTop />
                        <div className="mb-3">
                            <label htmlFor="presetName" className="form-label">
                                Preset Name
                            </label>
                            <input
                                id="presetName"
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="name"
                                onChange={this.updateInput}
                                value={this.state.name}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label
                                htmlFor="presetDescription"
                                className="form-label"
                            >
                                Preset Description (Optional)
                            </label>
                            <TextareaAutosize
                                id="presetDescription"
                                className="form-control"
                                type="text"
                                name="description"
                                placeholder=""
                                onChange={this.updateInput}
                                value={this.state.description}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="demoLink" className="form-label">
                                Demo Link (Optional)
                            </label>
                            <input
                                name="demoLink"
                                type="text"
                                className="form-control"
                                id="demoLink"
                                placeholder=""
                                onChange={this.updateInput}
                                value={this.state.demoLink}
                            ></input>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Preset Tags</label>
                            <TagsInput
                                tags={this.state.tags}
                                setTags={(tags) => {
                                    this.setState({ tags: tags });
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <InputSelect
                                label="Visibility"
                                options={VISIBILITY_OPTIONS}
                                value={this.state.visibility}
                                onChange={this.onVisibilityOptionChange}
                            />
                        </div>
                        {this.state.presetType === PRESET_TYPES.schema && (
                            <div className="mb-3">
                                <label className="form-label d-block fw-bold">
                                    Submit as schema
                                </label>
                                <div className="d-flex">
                                    <input
                                        name="submitAsSchema"
                                        type="checkbox"
                                        className="form-check-input me-2"
                                        id="demoLink"
                                        placeholder=""
                                        onChange={this.updateInput}
                                        checked={this.state.submitAsSchema}
                                    ></input>
                                    <label
                                        className="form-check-label"
                                        for="submitAsSchema"
                                    >
                                        Checking this checkbox will alsos submit
                                        this preset as a schema and have a
                                        chance to make it a ToneNotebook schema
                                        for all users to use.
                                    </label>
                                </div>
                            </div>
                        )}
                        {this.state.presetType === PRESET_TYPES.schema && (
                            <div className="mb-3">
                                <label className="form-label">
                                    Preview Schema
                                </label>
                                <SchemaViewer schema={this.schema} />
                            </div>
                        )}
                        {this.state.presetType === PRESET_TYPES.thrii && (
                            <div className="mb-3">
                                <label className="form-label">
                                    Preview THR-ii Schema
                                </label>
                                <PresetViewer preset={this.state.fileJson} />
                            </div>
                        )}
                    </div>
                )}
                <hr className="mt-5" />
                {this.renderStepControls()}
                <br />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(PresetsCreatePage));
