import React from "react";
import SchemaKnob from "../schema-knob/schema-knob.component";
import SchemaSelector from "../schema-selector/schema-selector.component";
import SchemaSwitch from "../schema-switch/schema-switch.component";
import { SCHEMAS } from "../../utils/constants.utils";

const RESPONSIVE_CLASSNAMES = "col-6 col-sm-4 col-md-3 col-xl-2 p-1";

class SchemaViewer extends React.Component {
    renderSchemaAttribute(attributes, i) {
        let content = null;

        if (attributes.type === SCHEMAS.knob.type) {
            content = <SchemaKnob attributes={attributes} />;
        } else if (attributes.type === SCHEMAS.selector.type) {
            content = <SchemaSelector attributes={attributes} />;
        } else if (attributes.type === SCHEMAS.switch.type) {
            content = <SchemaSwitch attributes={attributes} />;
        }
        return (
            <div className="bg-yamaha p-1 p-lg-4 p-xl-2 h-100">{content}</div>
        );
    }
    render() {
        const schemaAttributes = this.props.schema.attributes;

        return (
            <div className="row">
                {schemaAttributes.map((ele, i) => {
                    return (
                        <div
                            className={RESPONSIVE_CLASSNAMES}
                            key={ele.name + "-" + i}
                        >
                            {this.renderSchemaAttribute(ele, i)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default SchemaViewer;
