import React from "react";
import { auth } from "../../firebase/firebase.utils";
import { withRouter, Link } from "react-router-dom";
import axiosConfig from "../../axios/axios";
import { APP_NAME } from "../../utils/constants.utils";

import SchemaAttributeEditor from "../../components/schema-attributes-editor/schema-attributes-editor.component";
// import Spinner from "../../components/spinner/spinner.component";

import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";

class PresetsUpdateSchemaPage extends React.Component {
    constructor(props) {
        super(props);
        this.newSchema = null;
        this.state = {
            name: "",
            description: "",
            presetTags: "",
            presetId: this.props.match.params.presetId,
            errorMessage: "",
            demoLink: "",
            tags: [],
            pageLoading: true,
            initialSchema: null
        };
    }

    componentDidMount = (props) => {
        if (this.props.authState.isSignedIn) {
            this.getPreset();
        }
        document.title = APP_NAME + " | Edit Schema";
    };

    componentDidUpdate = (prevProps) => {
        // if user just signed in, then try getting the preset again for private presets and like/save status
        if (
            !prevProps.authState.isSignedIn &&
            this.props.authState.isSignedIn
        ) {
            this.getPreset();
        }
    };

    onSchemaAttributeUpdate = (newAttributes) => {
        if (newAttributes && this.state.initialSchema) {
            console.log("Updating");
            this.newSchema = { ...this.state.initialSchema };
            this.newSchema.attributes = newAttributes;
        }
    };

    submitChanges = async () => {
        const presetId = this.state.presetId;
        let result;
        if (this.props.authState.isSignedIn) {
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            const bodyParams = { newSchema: { ...this.newSchema } };
            result = await axiosConfig.put(
                `/presets/${presetId}/schema`,
                bodyParams,
                config
            );
        }
        console.log(result);
        this.props.history.push(`/presets/${this.state.presetId}`);
    };

    getPreset = async () => {
        try {
            const presetId = this.state.presetId;
            let result;
            if (this.props.authState.isSignedIn) {
                const authToken = await auth.currentUser.getIdToken();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                };
                result = await axiosConfig.get(`/presets/${presetId}`, config);
            }
            const { presetType } = result.data;

            this.setState({
                presetDoc: result.data,
                presetType,
                pageLoading: false,
                presetDocIsLoaded: true,
                initialSchema: result.data.schema
            });
        } catch (err) {
            console.log(err);
            this.setState({ loading: false, presetDocIsLoaded: true });
            alert("Something went wrong.");
        }
    };

    render = () => {
        return (
            <div className="container my-5 pt-3">
                <h1 className="my-3">Update preset schema</h1>
                <SchemaAttributeEditor
                    onSchemaAttributeUpdate={this.onSchemaAttributeUpdate}
                    initialSchema={this.state.initialSchema}
                    limitedAccess={true}
                />
                <hr />
                <div className="d-flex justify-content-between">
                    <Link to={"/presets/" + this.props.match.params.presetId}>
                        <button className="btn btn-outline-danger">
                            Discard changes
                        </button>
                    </Link>
                    <button
                        className="btn btn-primary"
                        onClick={this.submitChanges}
                    >
                        Submit changes
                    </button>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(PresetsUpdateSchemaPage));
