import React from "react";
import InputText from "../input-text/input-text.component";
import SchemaSelector from "../schema-selector/schema-selector.component";
import InputTags from "../input-tags/input-tags.component";
import InputSelect from "../input-select/input-select.component";
import SchemaControl from "../schema-control/schema-control.component";

class SchemaSelectorAdmin extends React.Component {
    onSelectorOptionsChange = (tags) => {
        this.props.onFieldChange("options", tags, this.props.index);
    };
    onSelectorChange = (event) => {
        this.props.onFieldChange("value", event.target.value, this.props.index);
    };
    onLabelChange = (event) => {
        this.props.onFieldChange("label", event.target.value, this.props.index);
    };
    onDelete = () => {
        this.props.deleteElement(this.props.index);
    };
    onMoveEleUp = () => {
        this.props.moveElementUp(this.props.index);
    };
    onMoveEleDown = () => {
        this.props.moveElementDown(this.props.index);
    };
    render() {
        const attributes = this.props.attributes;
        const fullAccess = !this.props.limitedAccess;
        return (
            <div>
                <SchemaSelector attributes={attributes} />
                {fullAccess && (
                    <SchemaControl
                        moveUp={this.onMoveEleUp}
                        moveDown={this.onMoveEleDown}
                        onDelete={this.onDelete}
                    />
                )}
                <InputSelect
                    options={attributes.options}
                    onChange={this.onSelectorChange}
                    label="Selected"
                />
                {fullAccess && (
                    <InputText
                        value={attributes.label}
                        onChange={this.onLabelChange}
                        label="Label"
                    />
                )}
                {fullAccess && (
                    <InputTags
                        tags={attributes.options}
                        setTags={this.onSelectorOptionsChange}
                        label="Options"
                    />
                )}
            </div>
        );
    }
}

export default SchemaSelectorAdmin;
