import React from "react";
import InputText from "../input-text/input-text.component";
import SchemaKnob from "../schema-knob/schema-knob.component";
import SchemaControl from "../schema-control/schema-control.component";

const knobValueBase = 100;
class SchemaKnobAdmin extends React.Component {
    onChange = (e) => {
        const index = this.props.index;
        this.props.inputFieldOnChange(e, index);
    };
    onLabelChange = (event) => {
        this.props.onFieldChange("label", event.target.value, this.props.index);
    };
    onValueChange = (event) => {
        this.props.onFieldChange(
            "value",
            event.target.value / knobValueBase,
            this.props.index
        );
    };
    onMaxChange = (event) => {
        this.props.onFieldChange("max", event.target.value, this.props.index);
    };
    onDelete = () => {
        this.props.deleteElement(this.props.index);
    };
    onMoveEleUp = () => {
        this.props.moveElementUp(this.props.index);
    };
    onMoveEleDown = () => {
        this.props.moveElementDown(this.props.index);
    };
    render() {
        const attributes = this.props.attributes;
        const fullAccess = !this.props.limitedAccess;
        return (
            <div>
                <SchemaKnob attributes={this.props.attributes} />
                {fullAccess && (
                    <SchemaControl
                        moveUp={this.onMoveEleUp}
                        moveDown={this.onMoveEleDown}
                        onDelete={this.onDelete}
                    />
                )}
                <InputText
                    value={Math.round(attributes.value * knobValueBase)}
                    onChange={this.onValueChange}
                    label="Value"
                    type="number"
                    min={0}
                    max={knobValueBase}
                />
                {fullAccess && (
                    <InputText
                        value={attributes.label}
                        onChange={this.onLabelChange}
                        label="Label"
                    />
                )}
                {/* {fullAccess && <InputText value={attributes.max} onChange={this.onMaxChange} label="Max"/>} */}
            </div>
        );
    }
}

export default SchemaKnobAdmin;
