import React from "react";
import { firestore as db, auth } from "../../firebase/firebase.utils";
import { withRouter } from "react-router-dom";
import axiosConfig from "../../axios/axios";

import Spinner from "../../components/spinner/spinner.component";
import TagsInput from "../../components/tags-input/tags-input.component";
import InputSelect from "../../components/input-select/input-select.component";
import { VISIBILITY_OPTIONS } from "../../utils/constants.utils";

class PresetsUpdatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            presetTags: "",
            presetId: this.props.match.params.presetId,
            loading: false,
            errorMessage: "",
            demoLink: "",
            tags: [],
            visibility: VISIBILITY_OPTIONS[0],
            pageLoading: true
        };
    }

    componentDidMount() {
        this.getPreset();
    }

    updateInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    getPreset = () => {
        // console.log("listing", this.state.presetId);
        const presetRef = db
            .collection("tone_profiles_db")
            .doc(this.state.presetId);
        presetRef
            .get()
            .then((preset) => {
                if (preset.exists) {
                    const { description, name, tags, demoLink, visibility } =
                        preset.data();

                    this.setState({
                        description,
                        name,
                        tags,
                        demoLink,
                        visibility,
                        pageLoading: false
                    });

                    console.log(this.state);
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ pageLoading: false });
            });
    };

    handleSubmission = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, errorMessage: "" });
        try {
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };

            const body = {
                name: this.state.name,
                description: this.state.description,
                tags: this.state.tags,
                demoLink: this.state.demoLink,
                visibility: this.state.visibility
            };
            await axiosConfig.put(
                `/presets/${this.state.presetId}`,
                body,
                config
            );

            this.props.history.push(`/presets/${this.state.presetId}`);
        } catch (err) {
            console.log(err);
            if (err.response) console.log(err.response);
            const errorMsg = err.response.data && err.response.data.message;
            if (errorMsg) this.setState({ errorMessage: errorMsg });
            this.setState({ loading: false });
        }
    };

    render() {
        if (this.state.pageLoading)
            return (
                <div className="page-wrapper">
                    <Spinner />
                </div>
            );
        return (
            <div className="container my-5 pt-3">
                <h1 className="my-3">Update preset</h1>
                <form onSubmit={this.handleSubmission}>
                    <div className="mb-3">
                        <label htmlFor="presetName" className="form-label">
                            Preset Name
                        </label>
                        <input
                            id="presetName"
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="name"
                            onChange={this.updateInput}
                            value={this.state.name}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="presetDescription"
                            className="form-label"
                        >
                            Preset Description (Optional)
                        </label>
                        <textarea
                            id="presetDescription"
                            className="form-control"
                            type="text"
                            name="description"
                            placeholder=""
                            onChange={this.updateInput}
                            value={this.state.description}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="demoLink" className="form-label">
                            Demo Link (Optional)
                        </label>
                        <input
                            name="demoLink"
                            type="text"
                            className="form-control"
                            id="demoLink"
                            placeholder=""
                            onChange={this.updateInput}
                            value={this.state.demoLink}
                        ></input>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Preset Tags</label>
                        <TagsInput
                            tags={this.state.tags}
                            setTags={(tags) => {
                                this.setState({ tags: tags });
                            }}
                        />
                    </div>
                    <div className="mb-3">
                        <InputSelect
                            label="Visibility"
                            options={VISIBILITY_OPTIONS}
                            value={this.state.visibility}
                            onChange={this.updateInput}
                            name="visibility"
                        />
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <button
                            className="btn btn-outline-danger"
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                    `/presets/${this.state.presetId}`
                                );
                            }}
                        >
                            Discard Changes
                        </button>
                        {this.state.errorMessage && (
                            <div className="text-danger">
                                {this.state.errorMessage}
                            </div>
                        )}
                        {this.state.loading ? (
                            <Spinner />
                        ) : (
                            <button
                                type="submit"
                                className="btn btn-primary me-3"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(PresetsUpdatePage);
