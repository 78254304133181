import React from "react";

class InputCheckbox extends React.Component {
    render() {
        return (
            <div className="my-2">
                <label>
                    {this.props.label}
                    <input
                        type="checkbox"
                        name={this.props.name}
                        checked={this.props.value}
                        onChange={this.props.onChange}
                    />
                </label>
            </div>
        );
    }
}

export default InputCheckbox;
