import React from "react";

class SchemaControl extends React.Component {
    render() {
        const { moveUp, moveDown, onDelete } = this.props;
        return (
            <div className="d-flex justify-content-around align-items-center my-3">
                <button className="btn btn-outline-secondary" onClick={moveUp}>
                    <i className="fa-solid fa-arrow-left"></i>
                </button>
                <button
                    className="btn btn-outline-danger mx-1"
                    onClick={onDelete}
                >
                    <i className="fa-solid fa-trash-can me-1"></i>delete
                </button>
                <button
                    className="btn btn-outline-secondary"
                    onClick={moveDown}
                >
                    <i className="fa-solid fa-arrow-right"></i>
                </button>
            </div>
        );
    }
}

export default SchemaControl;
