import React from "react";
import preval from "preval.macro";
import { socialMediaList } from "../../utils/constants.utils";
import { Link } from "react-router-dom";

function Footer() {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return (
        <footer className="footer mt-auto">
            <div className="bg-light">
                <div className="container py-4">
                    <div className="d-sm-flex justify-content-between align-items-center">
                        <div className="text-muted my-2">
                            Thank you for using ToneNotebook.
                        </div>
                        <div className="d-flex align-items-center">
                            {socialMediaList.map(({ icon, link, text }) => {
                                return (
                                    <a
                                        className="text-muted text-large me-3 opacity-75 hover-highlight"
                                        href={link}
                                        target="_blank"
                                        rel="noreferrer"
                                        key={text}
                                    >
                                        {icon}
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <hr />
                {/* Copyright delaration */}
                <div className="text-center text-muted text-size-75">
                    <p>
                        © 2022, ToneNotebook. All Rights Reserved.
                        <br />
                        All tones submitted to ToneNotebook by its members are
                        owned by the individual members.
                        <br />
                        ToneNotebook is independently owned and run with no
                        sponsorship or endorsement by any of the mentioned
                        brands.
                        <br />
                        All product and company names are trademarks™ or
                        registered® trademarks of their respective holders.
                        <br />
                        Use of them does not imply any affiliation with or
                        endorsement by them.
                    </p>
                </div>
                {/* Bottom links */}
                <div className="text-muted text-center text-size-75">
                    <Link
                        className=" no-styling mx-2 hover-underline"
                        to="/terms-and-conditions"
                    >
                        Terms and Conditions
                    </Link>
                    {/* <Link className=" no-styling mx-2 hover-underline" to="/terms-and-conditions">Legal</Link> */}
                    <Link
                        className=" no-styling mx-2 hover-underline"
                        to="/privacy-policy"
                    >
                        Privacy Policy
                    </Link>
                </div>
                <hr className="my-1" />
                <div className="text-center text-muted text-size-75">
                    Build: {dateTimeStamp}
                </div>
            </div>
        </footer>
    );
}

export default Footer;
