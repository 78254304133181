import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

class SchemaSwitch extends React.Component {
    render() {
        const { value, label, labelPos, labelNeg } = this.props.attributes;
        return (
            <div className="d-flex flex-column bg-yamaha justify-content-center align-items-center p-1 w-100 rounded-3">
                <div className="m-3">
                    <CircularProgressbar
                        value={value ? 100 : 0}
                        text={value ? labelPos : labelNeg}
                    />
                </div>
                <strong className="text-center">{label}</strong>
                <label>Switch</label>
            </div>
        );
    }
}

export default SchemaSwitch;
