import React from "react";
import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SchemaBuilder from "../../components/schema-builder/schema-builder.component";
import { auth } from "../../firebase/firebase.utils";
import axiosConfig from "../../axios/axios";

const INITIAL_STATE = {};

class CreateSchemaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.schema = {};
    }

    uploadDocument = () => {
        try {
            this.setState({ submitting: true }, async () => {
                if (!this.props.authState.isSignedIn)
                    alert(
                        "You must be signed in to share, please sign up or sign in."
                    );
                else {
                    const bodyParams = { ...this.schema };
                    const authToken = await auth.currentUser.getIdToken();
                    const config = {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    };
                    const res = await axiosConfig.post(
                        "/schemas",
                        bodyParams,
                        config
                    );
                    console.log(res);
                    alert(
                        `Uploaded as ${res.data.schema.brand} ${res.data.schema.model} version ${res.data.schema.version}`
                    );
                }
            });
        } catch (err) {
            console.log(err);
            this.setState({ submitting: false });
            alert("Something went wrong, please refresh and try again");
        }
    };
    onSchemaUpdate = (schema) => {
        this.schema = schema;
    };

    render = () => {
        return (
            <div className="create-schema-page container page-wrapper">
                <h1 className="my-3">Create Schema</h1>
                <SchemaBuilder
                    onSchemaUpdate={this.onSchemaUpdate}
                    user={this.props.user}
                    createSchemaMode={true}
                />
                <hr />
                <div>
                    <button
                        className="btn btn-primary me-2"
                        onClick={() => console.log(this.props)}
                    >
                        Log props
                    </button>
                    <button
                        className="btn btn-primary me-2"
                        onClick={() => console.log(this.schema)}
                    >
                        Log schema
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={this.uploadDocument}
                    >
                        Upload
                    </button>
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(CreateSchemaPage));
