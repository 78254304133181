import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
// import 'react-circular-progressbar/dist/styles.css';

class SchemaKnob extends React.Component {
    render(props) {
        const { value, label, max } = this.props.attributes;
        return (
            <div className="d-flex flex-column bg-yamaha justify-content-center align-items-center p-1 w-100 rounded-3">
                <div className="m-3">
                    <CircularProgressbar
                        value={value * max}
                        maxValue={max}
                        text={`${Math.round(value * 100)}%`}
                    />
                </div>
                <strong className="text-center">{label}</strong>
                <label>Knob</label>
            </div>
        );
    }
}
export default SchemaKnob;
