import React from "react";

import Knob from "../knob/knob.component";

function PresetViewerKnob(props) {
    const min = props.min || 0;

    let max = props.max || 1;
    if (props.max === 0) max = 0;

    let onChange = props.onChange || function () {};

    // const step = props.step || 0.001;
    let displayValue = props.value;

    const range = max - min;
    displayValue = displayValue - min;
    displayValue = displayValue / range;

    displayValue = Math.round(displayValue * 100);

    const size = props.size || 60;

    const styles = {
        paddingRight: "2.3rem",
        paddingLeft: "1rem",
        paddingTop: "1rem",
        paddingBottom: "1.5rem"
    };

    return (
        <div className="d-flex justify-content-center align-items-center flex-column">
            <div style={styles}>
                <Knob
                    size={size}
                    numTicks={100}
                    degrees={260}
                    min={min}
                    max={max}
                    value={props.value}
                    onChange={onChange}
                    readOnly={props.readOnly || true}
                />
            </div>
            <div className="pt-1">
                <strong>{props.label}</strong>{" "}
            </div>
            <div>{displayValue}</div>
        </div>
    );
}

export default PresetViewerKnob;
