import React from "react";
import Select from "react-select";

class InputReactSelect extends React.Component {
    render() {
        // const {options, onChange, label, defaultValue} = this.props;
        return (
            <div className="w-100">
                <label>{this.props.label}</label>
                <Select {...this.props} />
            </div>
        );
    }
}

export default InputReactSelect;
