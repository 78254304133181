import { createSlice } from "@reduxjs/toolkit";
import { signInWithGoogle } from "../../firebase/firebase.utils";

const INITIAL_STATE = {
    isSignedIn: false,
    isLoading: false,
    user: {
        displayName: "",
        email: "",
        uid: "",
        isAdmin: false
    }
};

export const userSlice = createSlice({
    name: "auth",
    initialState: INITIAL_STATE,
    // Reducers here take a state and an optional action as parameters
    reducers: {
        startAuthLoading: (state) => {
            state.isLoading = true;
        },
        stopAuthLoading: (state) => {
            state.isLoading = false;
        },
        signInSuccessful: (state, action) => {
            const user = {
                displayName: action.payload.displayName,
                email: action.payload.email,
                uid: action.payload.uid,
                isAdmin: !!action.payload.isAdmin
            };
            state.isLoading = false;
            state.user = user;
            state.isSignedIn = true;
        },
        signOutSuccessful: () => {
            return INITIAL_STATE;
        }
    }
});

const { reducer, actions } = userSlice;

// Export reducer
export default reducer;

// Export actions
export const {
    signInSuccessful,
    signOutSuccessful,
    startAuthLoading,
    stopAuthLoading
} = actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAuth = (state) => {
    return state.authState;
};
export const signIn = () => async (dispatch) => {
    try {
        const result = await signInWithGoogle();
        console.log(result);
        dispatch(
            signInSuccessful({
                displayName: result.user.displayName,
                email: result.user.email,
                uid: result.user.uid
            })
        );
        // result.use
        // displayName: "Dylan L"
        // email: "dylan0226l@gmail.com"
    } catch (err) {
        console.log(err);
    }
};
