import React from "react";
import { SCHEMAS } from "../../utils/constants.utils";
import SchemaKnobAdmin from "../schema-knob-admin/schema-knob-admin.component";
import SchemaSelectorAdmin from "../schema-selector-admin/schema-selector-admin.component";
import SchemaSwitchAdmin from "../schema-switch-admin/schema-switch-admin.component";
import PropTypes from "prop-types";

const INITIAL_STATE = {
    attributes: []
};

const RESPONSIVE_CLASSNAMES = "col-6 col-sm-4 col-md-3 col-xl-2 p-1";

class SchemaAttributeEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidUpdate = (prevProps) => {
        // onSchemaAttributeUpdate is a required function, it is called to update the parent component with new attributes.
        this.props.onSchemaAttributeUpdate({ ...this.state }.attributes);

        // If new schema is mounted
        if (this.props.initialSchema && !prevProps.initialSchema) {
            return this.setState({
                attributes: this.props.initialSchema.attributes
            });
        }
        // Else if initialSchema is different from previously loaded
        else if (
            this.props.initialSchema &&
            prevProps.initialSchema &&
            this.props.initialSchema.id !== prevProps.initialSchema.id
        ) {
            return this.setState({
                attributes: this.props.initialSchema.attributes
            });
        }
        // Else if new schema is empty
        else if (prevProps.initialSchema && !this.props.initialSchema) {
            return this.setState(INITIAL_STATE);
        }
    };

    addKnob = () => this.addElement(SCHEMAS.knob);
    addSelector = () => this.addElement(SCHEMAS.selector);
    addSwitch = () => this.addElement(SCHEMAS.switch);
    addElement = (newField) => {
        this.setState((prevState) => ({
            attributes: [...prevState.attributes, newField]
        }));
    };
    deleteElement = (index) => {
        const array = [...this.state.attributes]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ attributes: array });
        }
    };
    moveElementUp = (index) => {
        if (index > 0) {
            const array = [...this.state.attributes]; // make a separate copy of the array
            let item = array.splice(index, 1)[0];
            array.splice(index - 1, 0, item);
            this.setState({ attributes: array });
        }
    };
    moveElementDown = (index) => {
        const array = [...this.state.attributes]; // make a separate copy of the array
        let item = array.splice(index, 1)[0];
        array.splice(index + 1, 0, item);
        this.setState({ attributes: array });
    };

    editSchemaField = (index, newSchemaField) => {
        const fields = this.state.attributes.slice(); //copy the array
        fields[index] = newSchemaField; //execute the manipulations
        this.setState({
            attributes: fields
        }); //set the new state
    };

    // Use this function
    onSchemaAttributeChange = (fieldName, newValue, index) => {
        let newSchemaField = { ...this.state.attributes[index] };
        newSchemaField[fieldName] = newValue;
        this.editSchemaField(index, newSchemaField);
    };

    renderSchemaField = (attributes, i) => {
        let content = null;
        const limitedAccess = this.props.limitedAccess ? true : false;
        if (attributes.type === SCHEMAS.knob.type) {
            content = (
                <SchemaKnobAdmin
                    index={i}
                    attributes={attributes}
                    onFieldChange={this.onSchemaAttributeChange}
                    deleteElement={this.deleteElement}
                    moveElementUp={this.moveElementUp}
                    moveElementDown={this.moveElementDown}
                    limitedAccess={limitedAccess}
                />
            );
        } else if (attributes.type === SCHEMAS.selector.type) {
            content = (
                <SchemaSelectorAdmin
                    index={i}
                    attributes={attributes}
                    onFieldChange={this.onSchemaAttributeChange}
                    deleteElement={this.deleteElement}
                    moveElementUp={this.moveElementUp}
                    moveElementDown={this.moveElementDown}
                    limitedAccess={limitedAccess}
                />
            );
        } else if (attributes.type === SCHEMAS.switch.type) {
            content = (
                <SchemaSwitchAdmin
                    index={i}
                    attributes={attributes}
                    onFieldChange={this.onSchemaAttributeChange}
                    deleteElement={this.deleteElement}
                    moveElementUp={this.moveElementUp}
                    moveElementDown={this.moveElementDown}
                    limitedAccess={limitedAccess}
                />
            );
        }
        return (
            <div
                className={RESPONSIVE_CLASSNAMES}
                key={attributes.name + "" + i}
            >
                <div className="bg-yamaha p-2 h-100">{content}</div>
            </div>
        );
    };

    render = () => {
        return (
            <div className="">
                <div className="row my-3">
                    {/* <div> {this.props.limitedAccess ? "" : "Custom Schema"}</div> */}
                    {this.state.attributes.map(this.renderSchemaField)}
                    {!this.props.limitedAccess && (
                        <div className={RESPONSIVE_CLASSNAMES}>
                            <div className="d-flex flex-column h-100 align-items-center justify-content-center bg-light border border-3 py-3">
                                <button
                                    className="btn btn-primary my-2"
                                    onClick={this.addKnob}
                                >
                                    <i className="fa-solid fa-plus me-1"></i>
                                    Knob
                                </button>
                                <button
                                    className="btn btn-primary my-2"
                                    onClick={this.addSelector}
                                >
                                    <i className="fa-solid fa-plus me-1"></i>
                                    Selector
                                </button>
                                <button
                                    className="btn btn-primary my-2"
                                    onClick={this.addSwitch}
                                >
                                    <i className="fa-solid fa-plus me-1"></i>
                                    Switch
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

SchemaAttributeEditor.propTypes = {
    onSchemaAttributeUpdate: PropTypes.func.isRequired,
    limitedAccess: PropTypes.bool
};
export default SchemaAttributeEditor;
