import React from "react";

class InputButtonWithLoading extends React.Component {
    render() {
        const isloading = this.props.isloading;
        return (
            <button
                {...this.props}
                isloading="null"
                className={` ${this.props.className}`}
                disabled={isloading ? true : false}
            >
                {isloading ? (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    this.props.children
                )}
            </button>
        );
    }
}

export default InputButtonWithLoading;
