import React from "react";
import { Link, withRouter } from "react-router-dom";
// import Spinner from "../../components/spinner/spinner.component";
import PresetInfo from "../../components/preset-info/preset-info.component";
import { APP_NAME } from "../../utils/constants.utils";
import { dataLayerPush } from "../../utils/tagging.utils";
import axiosConfig from "../../axios/axios";
import queryString from "query-string";
import {
    SUPPORTED_BRANDS,
    SUPPORTED_MODELS
} from "../../utils/constants.utils";
import InputReactSelect from "../../components/input-react-select/input-react-select.component";
import SkeletonLoader from "../../components/spinner-preset-info/spinner-preset-info.component";
import Page from "../../components/page/page.component";

SUPPORTED_MODELS["Any"] = ["Any"];
SUPPORTED_BRANDS.unshift("Any");
const brandOptions = SUPPORTED_BRANDS.map((item) => {
    return { value: item, label: item };
});
const modelOptions = SUPPORTED_MODELS[SUPPORTED_BRANDS[0]].map((item) => {
    return { value: item, label: item };
});

class ProfilesList extends React.Component {
    constructor(props) {
        super(props);

        this.lastVisiblePresetId = null;
        this.tags = [];

        // Parse parameters from URL
        const parsed = queryString.parse(props.location.search);
        const { orderBy, orderDesc, search, itemsPerPage } = parsed;

        this.state = {
            toneProfiles: [],
            itemsPerPage: itemsPerPage ? parseInt(itemsPerPage) : 10,
            loadedAll: false,
            orderBy: orderBy || "lastUpdated",
            orderDesc: orderDesc || true,
            search: search || "",
            loading: false,

            modelOptions: modelOptions,
            brand: SUPPORTED_BRANDS[0],
            model: SUPPORTED_MODELS[SUPPORTED_BRANDS[0]][0]
        };
        this.searchedOnMount = false;
    }

    componentDidMount() {
        this.listPresets();
        document.title = APP_NAME + " | Explore";
    }

    handleOrderByChange = (e) => {
        const { orderBy, orderDesc } = JSON.parse(e.target.value);
        const newState = { orderBy, orderDesc };
        this.setState(newState);
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSearchSubmit = async (e) => {
        e.preventDefault();
        dataLayerPush("search", { details: "Search on explore" });
        this.lastVisiblePresetId = null;
        this.setState(
            {
                loadedAll: false,
                toneProfiles: []
            },
            async () => {
                await this.listPresets();
            }
        );
    };

    listPresets = async () => {
        this.setState({ loading: true });
        try {
            // Do nothing if all is loaded
            if (this.state.loadedAll) return;

            if (this.state.search) {
                const tags = this.state.search
                    .replace(/[^0-9a-zA-Z\s,]+/g, "")
                    .toLowerCase()
                    .split(",")
                    .map((item) => item.trim());
                this.tags = tags;
            } else {
                this.tags = [];
            }

            const searchParams = {
                tags: this.tags,
                orderBy: this.state.orderBy,
                orderDesc: this.state.orderDesc,
                itemsPerPage: this.state.itemsPerPage,
                lastVisiblePresetId: this.lastVisiblePresetId,
                brand: this.state.brand,
                model: this.state.model
            };

            const { orderBy, orderDesc, itemsPerPage } = searchParams;

            // const queryString = jsonToQueryString(searchParams)
            const res = await axiosConfig.post("/presets/search", searchParams);
            const { presets, loadedAll, lastVisiblePresetId } = res.data;
            this.lastVisiblePresetId = lastVisiblePresetId;
            this.setState({
                toneProfiles: this.state.toneProfiles.concat(presets),
                loadedAll
            });

            // Update URL
            // If this is not the initial search and it is sstill on /presets page.
            if (
                this.searchedOnMount &&
                this.props.history.location.pathname === "/presets"
            ) {
                this.props.history.push({
                    pathname: "/presets",
                    search: `?search=${this.state.search}&orderBy=${orderBy}&orderDesc=${orderDesc}&itemsPerPage=${itemsPerPage}&lastVisiblePresetId=${lastVisiblePresetId}`
                });
            } else {
                this.searchedOnMount = true;
            }
        } catch (err) {
            console.log(err);
            alert("Something went wrong, please refresh and try again");
        }
        this.setState({ loading: false });
    };

    onBrandSelection = (event) => {
        const brand = event.value;
        const models = SUPPORTED_MODELS[brand].map((item) => {
            return { value: item, label: item };
        });
        this.setState({ modelOptions: models, brand }, () =>
            this.onModelSelection(models[0])
        );
    };

    onModelSelection = (event) => {
        const model = event.value;
        this.setState({ model });
    };

    render() {
        return (
            <Page>
                <h1 className="my-5 text-center">Explore Tone Profiles</h1>
                <div className=" mb-5">
                    <div className="d-flex justify-content-center align-items-end">
                        <div className="me-2 w-100">
                            <InputReactSelect
                                value={brandOptions.find(
                                    ({ value }) => value === this.state.brand
                                )}
                                options={brandOptions}
                                onChange={this.onBrandSelection}
                                label="Manufacture"
                            />
                        </div>
                        <div className="ms-2 w-100">
                            <InputReactSelect
                                value={this.state.modelOptions.find(
                                    ({ value }) => value === this.state.model
                                )}
                                options={this.state.modelOptions}
                                onChange={this.onModelSelection}
                                label="Model"
                            />
                        </div>
                    </div>
                    <form
                        className="input-group my-3"
                        onSubmit={this.handleSearchSubmit}
                    >
                        <MySelect
                            handleOrderByChange={this.handleOrderByChange}
                        />
                        <input
                            type="text"
                            onChange={this.handleChange}
                            name="search"
                            value={this.state.search}
                            className="form-control"
                        />
                        <button type="submit" className="btn btn-primary">
                            {" "}
                            Search{" "}
                        </button>
                    </form>
                    <div className="mt-4">
                        {this.state.toneProfiles.map((presetInfo, i) => {
                            return (
                                <div key={i}>
                                    <PresetInfo presetInfo={presetInfo} />
                                </div>
                            );
                        })}
                    </div>

                    <div className="text-center">
                        {this.state.loadedAll ? (
                            this.state.toneProfiles.length === 0 ? (
                                <div>
                                    --
                                    <br />
                                    We don't have anything that matches your
                                    query yet. <br />
                                    <Link to="/presets/create">
                                        Create your own and share it with the
                                        world!
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    --
                                    <br />
                                    That's all we have, didn't find one that you
                                    like? <br />
                                    <Link to="/presets/create">
                                        Create your own and share it with the
                                        world!
                                    </Link>
                                </div>
                            )
                        ) : this.state.loading ? (
                            <SkeletonLoader />
                        ) : (
                            <button
                                className="btn btn-primary px-5"
                                onClick={this.listPresets}
                            >
                                Load more
                            </button>
                        )}
                    </div>
                </div>
            </Page>
        );
    }
}

class MySelect extends React.Component {
    render() {
        const handleOrderByChange = this.props.handleOrderByChange;
        return (
            <div>
                <select className="form-select" onChange={handleOrderByChange}>
                    <option
                        value={JSON.stringify({
                            orderBy: "lastUpdated",
                            orderDesc: true
                        })}
                    >
                        Most Recent
                    </option>
                    {/* <option value={JSON.stringify({
                    orderBy: "lastUpdated",
                    orderDesc: false
                })}>Oldest</option> */}
                    {/* <option value={JSON.stringify({
                    orderBy: "name",
                    orderDesc: false
                })}>Name A-Z</option> */}
                    {/* <option value={JSON.stringify({
                    orderBy: "name",
                    orderDesc: true
                })}>Name Z-A</option> */}
                    <option
                        value={JSON.stringify({
                            orderBy: "numOfSaves",
                            orderDesc: true
                        })}
                    >
                        Most Saved
                    </option>
                    {/* <option value={JSON.stringify({
                    orderBy: "score",
                    orderDesc: true
                })}>Higest Rated</option> */}
                    {/* <option value={JSON.stringify({
                    orderBy: "downloads",
                    orderDesc: false
                })}>Least Popular</option> */}
                </select>
            </div>
        );
    }
}

export default withRouter(ProfilesList);
