import React from "react";

function Tags(props) {
    const tags = props.tags || [];
    return (
        <div>
            {tags.map((tag, i) => {
                return (
                    <span className="badge bg-secondary me-1" key={i}>
                        {tag}
                    </span>
                );
            })}
        </div>
    );
}

export default Tags;
