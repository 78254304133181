import React from "react";
import SchemaSwitch from "../schema-switch/schema-switch.component";
import InputText from "../input-text/input-text.component";
import InputCheckbox from "../input-checkbox/input-checkbox.component";
import SchemaControl from "../schema-control/schema-control.component";

class SchemaSwitchAdmin extends React.Component {
    onLabelChange = (event) => {
        this.props.onFieldChange("label", event.target.value, this.props.index);
    };
    onValueChange = (event) => {
        this.props.onFieldChange(
            "value",
            event.target.checked,
            this.props.index
        );
    };
    onLabelPosChange = (event) => {
        this.props.onFieldChange(
            "labelPos",
            event.target.value,
            this.props.index
        );
    };
    onLabelNegChange = (event) => {
        this.props.onFieldChange(
            "labelNeg",
            event.target.value,
            this.props.index
        );
    };
    onDelete = () => {
        this.props.deleteElement(this.props.index);
    };
    onMoveEleUp = () => {
        this.props.moveElementUp(this.props.index);
    };
    onMoveEleDown = () => {
        this.props.moveElementDown(this.props.index);
    };
    render() {
        const attributes = this.props.attributes;
        const fullAccess = !this.props.limitedAccess;
        return (
            <div className="">
                <SchemaSwitch attributes={attributes} />
                {fullAccess && (
                    <SchemaControl
                        moveUp={this.onMoveEleUp}
                        moveDown={this.onMoveEleDown}
                        onDelete={this.onDelete}
                    />
                )}
                <InputCheckbox
                    value={attributes.value}
                    onChange={this.onValueChange}
                    label="ON/OFF "
                />
                {fullAccess && (
                    <InputText
                        value={attributes.label}
                        onChange={this.onLabelChange}
                        label="Label"
                    />
                )}
                {fullAccess && (
                    <InputText
                        value={attributes.labelPos}
                        onChange={this.onLabelPosChange}
                        label="On label"
                    />
                )}
                {fullAccess && (
                    <InputText
                        value={attributes.labelNeg}
                        onChange={this.onLabelNegChange}
                        label="Off label"
                    />
                )}
            </div>
        );
    }
}

export default SchemaSwitchAdmin;
