import React from "react";

import Navbar from "./components/navbar/navbar.component";
import Footer from "./components/footer/footer.component";

import PresetsUpdatePage from "./pages/presets-update/presets-update.page";
import PresetsUpdateSchemaPage from "./pages/presets-update-schema/presets-update-schema.page";
import PresetsCreatePage from "./pages/presets-create/presets-create.page";
import PresetsGetPage from "./pages/presets-get/presets-get.page";
import PresetsPage from "./pages/presets/presets.page";
import ProfilePage from "./pages/profile/profile.page";
import AboutPage from "./pages/about/about.page";
import PreviewTHRII from "./pages/preview-thr-ii/preview-thr-ii.page";
import MyProfilePage from "./pages/my-profile/my-profile.page";
import LoginPage from "./pages/login/login.page";
import SignupPage from "./pages/signup/signup.page";
import LandingPage from "./pages/landing/landing.page";
import PocPage from "./pages/poc/poc.page";
import UserPage from "./pages/user/user.page";
import CreateSchemaPage from "./pages/create-schema/create-schema.page";
import ContactPage from "./pages/contact/contact.page";

import TermsAndConditionsPage from "./pages/legal/terms-and-conditions/terms-and-conditions.page";
import PrivacyPolicyPage from "./pages/legal/privacy-policy/privacy-policy.page";

import { auth } from "./firebase/firebase.utils";
import { connect } from "react-redux";
import {
    signInSuccessful,
    selectAuth,
    startAuthLoading,
    stopAuthLoading
} from "./redux/user/user.slice";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import { PROD_HOSTNAME } from "./utils/constants.utils";

import { getUserInfo } from "./api/user.api";

import "react-loading-skeleton/dist/skeleton.css";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidUpdate(prevProps) {
        // Push page views
        if (this.props.location !== prevProps.location) {
            // console.log("Route changed");
            // Reset dataLayer variables after changing page
            window.dataLayer.push(function () {
                this.reset();
            });
        }
    }
    componentDidMount() {
        if (window.location.hostname !== PROD_HOSTNAME) {
            window.dataLayer.push({ traffic_type: "internal" });
        }

        this.unlisten = auth.onAuthStateChanged(async (user) => {
            this.props.startAuthLoading();
            try {
                console.log(user);
                if (user) {
                    const { email, uid } = user;

                    const authToken = await auth.currentUser.getIdToken();
                    const userData = await getUserInfo(authToken, uid);
                    const { displayName, username, isAdmin } = userData.user;
                    this.props.signInSuccessful({
                        uid,
                        email,
                        displayName,
                        username,
                        isAdmin: !!isAdmin
                    });
                }
            } catch (err) {
                console.log(err);
            }
            this.props.stopAuthLoading();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        return (
            <div className="App d-flex flex-column min-vh-100">
                <header>
                    <Navbar />
                </header>
                <main className="">
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route
                            exact
                            path="/login"
                            render={() => <LoginPage />}
                        />
                        <Route
                            exact
                            path="/signup"
                            render={() =>
                                this.props.authState.isSignedIn ? (
                                    <Redirect to="/" />
                                ) : (
                                    <SignupPage />
                                )
                            }
                        />
                        <Route path="/poc" component={PocPage} />
                        <Route
                            exact
                            path="/presets/create"
                            component={PresetsCreatePage}
                        />
                        <Route path="/profile" component={ProfilePage} />
                        <Route exact path="/presets" component={PresetsPage} />
                        <Route
                            path="/presets/:presetId/update"
                            component={PresetsUpdatePage}
                        />
                        <Route
                            path="/presets/:presetId/update-schema"
                            component={PresetsUpdateSchemaPage}
                        />
                        <Route
                            path="/presets/:presetId"
                            component={PresetsGetPage}
                        />
                        <Route path="/user/:userId" component={UserPage} />
                        <Route
                            path="/profile-settings"
                            component={MyProfilePage}
                        />
                        <Route
                            path="/preview-thr-ii"
                            component={PreviewTHRII}
                        />
                        <Route path="/about" component={AboutPage} />
                        <Route path="/contact" component={ContactPage} />
                        <Route
                            path="/schema/create"
                            component={CreateSchemaPage}
                        />
                        <Route
                            path="/terms-and-conditions"
                            component={TermsAndConditionsPage}
                        />
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicyPage}
                        />
                    </Switch>
                </main>
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInSuccessful: ({ uid, email, displayName, username, isAdmin }) =>
            dispatch(
                signInSuccessful({ uid, email, displayName, username, isAdmin })
            ),
        startAuthLoading: () => {
            dispatch(startAuthLoading());
        },
        stopAuthLoading: () => {
            dispatch(stopAuthLoading());
        }
    };
};
const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
