import React, { useState } from "react";
import { auth } from "../../firebase/firebase.utils";
import { createUserProfileDocument } from "../../api/user.api";
import { withRouter } from "react-router-dom";
import { dataLayerPush } from "../../utils/tagging.utils";
import { USER_AUTH_TYPE } from "../../utils/constants.utils";

function SignupPage(props) {
    // const dispatch = useDispatch();
    const [displayName, setDisplayName] = useState("");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const submitSignup = async (e) => {
        try {
            e.preventDefault();
            // Validating username
            dataLayerPush("sign_up", {
                details: "Sign up attempt with email and password"
            });
            const result = await auth.createUserWithEmailAndPassword(
                email,
                password
            );
            const authType = USER_AUTH_TYPE.email;
            await createUserProfileDocument(result.user, {
                displayName,
                authType
            });
            window.location.reload();
        } catch (err) {
            console.log(err);
            if (err.message) setErrorMessage(err.message);
        }
    };

    return (
        <div className="container pt-3 d-flex align-items-center my-height-100">
            <div className="container-narrow">
                <h1 className="my-3">Create account</h1>
                <form className="" onSubmit={submitSignup}>
                    <div className="mb-3">
                        <label htmlFor="displayName" className="form-label">
                            Display Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="displayName"
                            placeholder="Jack Sparrow"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email address
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="name@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary me-3">
                        Register
                    </button>
                </form>
                {errorMessage && (
                    <div className="text-danger mt-1">{errorMessage}</div>
                )}
            </div>
        </div>
    );
}

export default withRouter(SignupPage);
