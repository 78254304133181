import React from "react";
import InputText from "../../components/input-text/input-text.component";
import InputTextareaAutosize from "../../components/input-textareaautosize/input-textareaautosize.component";
import InputButton from "../../components/input-buttonwithloading/buttonwithloading.component";
import axios from "axios";
import { socialMediaList } from "../../utils/constants.utils";
import { APP_NAME } from "../../utils/constants.utils";
import Page from "../../components/page/page.component";

const INITIAL_STATE = {
    userName: "",
    userEmail: "",
    userMessage: "",
    sendingEmail: false,
    emailSent: false,
    emailError: false
};

const SUCCESS_STATE = { ...INITIAL_STATE };
SUCCESS_STATE.emailSent = true;

const howWeCanHelpList = [
    {
        title: "Bug Report",
        details:
            "Found a software bug? Please let us know, we will fix it as soon as possbile."
    },
    {
        title: "Feature Suggestions",
        details:
            "Let us know if there are any features that we can improve or implement in the future."
    },
    {
        title: "Anything at All",
        details:
            "If you have any issues or questions at all, get in touch with us for a chat."
    }
];

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.service_id = "service_rrnbg7l";
        this.template_id = "template_gjj8esc";
        this.user_id = "8En9DLMzXggRn9DhL";
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        document.title = APP_NAME + " | Contact";
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };

    sendRestEmail = async (e) => {
        e.preventDefault();
        this.setState(
            {
                sendingEmail: true
            },
            async () => {
                const data = {
                    service_id: this.service_id,
                    template_id: this.template_id,
                    user_id: this.user_id,
                    template_params: {
                        "user-name": this.state.userName,
                        "user-email": this.state.userEmail,
                        "user-message": this.state.userMessage
                        // 'g-recaptcha-response': token
                    }
                };
                try {
                    await axios.post(
                        "https://api.emailjs.com/api/v1.0/email/send",
                        data
                    );
                    this.setState(SUCCESS_STATE);
                } catch (error) {
                    this.setState({ sendingEmail: false, emailError: true });
                    console.error(error);
                }
            }
        );
    };

    render = () => {
        const h2Margin = "my-3";
        return (
            <Page>
                <h1 className="my-3">Contact us</h1>
                <hr />
                <div className="row">
                    <form
                        className="col-md-6 col-lg-5"
                        onSubmit={this.sendRestEmail}
                    >
                        <h2 className={h2Margin}>Fill Out the Form</h2>
                        <InputText
                            required
                            label="Your name"
                            name="userName"
                            onChange={this.handleInputChange}
                        />
                        <InputText
                            required
                            label="Your email"
                            name="userEmail"
                            onChange={this.handleInputChange}
                        />
                        <InputTextareaAutosize
                            required
                            label="Your message"
                            name="userMessage"
                            onChange={this.handleInputChange}
                        />
                        <div className="d-md-flex justify-content-between align-items-center mt-3">
                            <div>
                                {this.state.emailSent && (
                                    <div className="text-success">
                                        {" "}
                                        Thank you for your email, we will get
                                        back to you soon.
                                    </div>
                                )}
                                {this.state.emailError &&
                                    !this.state.emailSent && (
                                        <div className="text-danger">
                                            {" "}
                                            Something went wrong, it's on us.
                                        </div>
                                    )}
                            </div>
                            <InputButton
                                type="submit"
                                className="btn btn-primary text-end"
                                isloading={this.state.sendingEmail}
                            >
                                Send
                            </InputButton>
                        </div>
                    </form>
                    <div className="col-md-6  col-lg-7">
                        <div className="ms-lg-4">
                            <h2 className={h2Margin}>How We Can Help</h2>
                            {howWeCanHelpList.map(({ title, details }) => {
                                return (
                                    <div key={title}>
                                        <div className="text-medium text-700">
                                            {title}
                                        </div>
                                        <p className="">{details}</p>
                                    </div>
                                );
                            })}
                            <h2 className={h2Margin}>Say Hi</h2>
                            <div className="">
                                {socialMediaList.map(({ icon, link, text }) => {
                                    return (
                                        <a
                                            href={link}
                                            className="me-3 d-flex align-items-center no-styling"
                                            key={text}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <div className="text-large text-primary me-3 my-0">
                                                {icon}
                                            </div>
                                            <div className="text-primary">
                                                {text}
                                            </div>
                                        </a>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    };
}

export default ContactPage;
