import React from "react";
import PresetViewer from "../../components/preset-viewer/preset-viewer.component";
import { APP_NAME } from "../../utils/constants.utils";

class PreviewThrIIPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileJson: null
        };
    }

    componentDidMount = () => {
        document.title = APP_NAME + " | Preview";
    };
    handleFileUpload = (e) => {
        const fileReader = new FileReader();
        const errMsg =
            "Sorry, something went wrong. Please make sure to upload a .thrl6p file";
        try {
            fileReader.readAsText(e.target.files[0], "UTF-8");
        } catch (err) {
            console.log(err);
            alert(errMsg);
        }
        fileReader.onload = (e) => {
            try {
                const fileJson = JSON.parse(e.target.result);
                // if (!name) throw new Error("Invalid file.");
                this.setState({
                    fileStr: e.target.result,
                    fileJson: fileJson
                });
            } catch (err) {
                console.log(err);
                alert(errMsg);
            }
        };
    };
    render() {
        return (
            <div className="page-wrapper container">
                <h1 className="my-3">Preview a Yamaha THR-ii Preset</h1>
                <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                        Select a .thrl6p file
                    </label>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={this.handleFileUpload}
                        required
                    />
                </div>
                <PresetViewer preset={this.state.fileJson} />
            </div>
        );
    }
}

export default PreviewThrIIPage;
