import React from "react";

class InputText extends React.Component {
    render() {
        return (
            <div className="my-2">
                <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                >
                    {this.props.label}
                </label>
                <input className="form-control" {...this.props} />
            </div>
        );
    }
}

export default InputText;
