import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, signOutSuccessful } from "../../redux/user/user.slice";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";
import Spinner from "../spinner/spinner.component";

function AuthSection(props) {
    const dispatch = useDispatch();
    const authState = useSelector(selectAuth);
    const { isSignedIn, user } = authState;
    const { displayName, email } = user;
    const logOut = async () => {
        try {
            await auth.signOut();
            props.closeNav();
            dispatch(signOutSuccessful());
        } catch (err) {
            console.log(err);
        }
    };
    const authSectionMobile = (
        <div>
            <hr />
            {isSignedIn ? (
                <div className="text-light text-center">
                    <div>
                        Signed in as{" "}
                        <span
                            data-tag-this="link_click"
                            data-tag-details="Navbar - Go to profile settings page (/profile-settings) on mobile navbar"
                            onClick={props.closeNav}
                        >
                            <Link to="/profile-settings">{displayName}</Link>
                        </span>
                    </div>
                    <button
                        data-tag-this="button_click"
                        data-tag-details="Navbar - Click on sign out button on mobile navbar"
                        type="button"
                        className="my-3 btn btn-outline-light"
                        onClick={logOut}
                    >
                        Sign out
                    </button>
                </div>
            ) : authState.isLoading ? (
                <Spinner className="color-light" />
            ) : (
                <button
                    type="button"
                    className="btn btn-outline-light my-3"
                    data-tag-this="button_click"
                    data-tag-details="Navbar - Click on sign in button on mobile navbar"
                    onClick={() => {
                        props.closeNav();
                        props.history.push("/login");
                    }}
                >
                    Sign in
                </button>
            )}
        </div>
    );
    const authSectionDesktop = (
        <div>
            {isSignedIn ? (
                <div>
                    <div
                        className="nav-link dropdown-toggle text-white d-flex align-items-center"
                        href="#"
                        id="navbarDarkDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-tag-this="button_click"
                        data-tag-details="Navbar - Click to expand user profile details on navbar desktop"
                    >
                        <div className="text-size-125 me-2">
                            <i className="fas fa-user-circle"></i>
                        </div>{" "}
                        {displayName}
                    </div>
                    <ul
                        className="dropdown-menu dropdown-menu-dark dropdown-menu-end text-center"
                        aria-labelledby="navbarDarkDropdownMenuLink"
                    >
                        <li>
                            <div className="dropdown-item disabled py-0 text-large-175">
                                <i className="fas fa-user-circle "></i>{" "}
                            </div>
                        </li>
                        <li>
                            <div className="dropdown-item disabled py-0">
                                <strong>{displayName}</strong>{" "}
                            </div>
                        </li>
                        <li>
                            <div className="dropdown-item disabled py-0">
                                {" "}
                                {email}{" "}
                            </div>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li
                            data-tag-this="link_click"
                            data-tag-details="Navbar - Go to saved presets page in user details dropdown on desktop"
                        >
                            <Link
                                className="dropdown-item"
                                to={`/profile/saved`}
                            >
                                Saved Presets
                            </Link>
                        </li>
                        <li
                            data-tag-this="link_click"
                            data-tag-details="Navbar - Go to uploaded presets page in user details dropdown on desktop"
                        >
                            <Link
                                className="dropdown-item"
                                to={`/profile/uploads`}
                            >
                                My Uploads
                            </Link>
                        </li>
                        <li
                            data-tag-this="link_click"
                            data-tag-details="Navbar - Go to profile settings (/profile-settings) in user details dropdown on desktop"
                        >
                            <Link
                                className="dropdown-item"
                                to="/profile-settings"
                            >
                                Profile Settings
                            </Link>
                        </li>
                        {/* <li
                            data-tag-this="link_click"
                            data-tag-details="Navbar - Go to public profile page (/user/:userId) in user details dropdown on desktop"
                        ><Link className="dropdown-item" to={`/user/${user.uid}`} >Public profile</Link></li> */}
                        <li
                            data-tag-this="button_click"
                            data-tag-details="Navbar - Click to sign out in user details dropdown on desktop"
                        >
                            <button
                                type="button"
                                className="dropdown-item text-danger"
                                onClick={logOut}
                            >
                                Sign out
                            </button>
                        </li>
                        <li
                            data-tag-this="button_click"
                            data-tag-details="Navbar - Click to close user details dropdown on desktop"
                        >
                            <div className="dropdown-item" href="#">
                                <button
                                    type="button"
                                    className="btn-close btn-close-white"
                                    aria-label="Close"
                                ></button>
                            </div>
                        </li>
                    </ul>
                </div>
            ) : authState.isLoading ? (
                <Spinner />
            ) : (
                <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={props.closeNav}
                    data-tag-this="button_click"
                    data-tag-details="Navbar - Click on sign in button on desktop navbar"
                >
                    <Link to="/login" className="no-styling">
                        Sign in
                    </Link>
                </button>
            )}
        </div>
    );
    return <div>{props.mobile ? authSectionMobile : authSectionDesktop}</div>;
}

export default withRouter(AuthSection);
