import React from "react";

// import bg from "../../img/bg-e-4-yellow-2.jpg";
// import bg from "../../img/wallpaper_new2.jpg";
import bg from "../../img/bg-prod-min.jpg";
import "./landing.styles.scss";
import { Link, withRouter } from "react-router-dom";

class LandingPage extends React.Component {
    componentDidMount() {
        // console.log(this.props.location);
    }
    render() {
        return (
            <div
                style={{
                    backgroundImage: `url(${bg})`,
                    height: "100vh",
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                }}
                className="landing-page"
            >
                <div className="outer-container">
                    <div className="container h-100">
                        <div className="row h-100 main-content">
                            <div className="col-12 col-md-6 mx-auto">
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <div className="mb-3 ">
                                        <h2>Introducing</h2>
                                        <h1 className="display-1 mb-0 bold mt-3 title">
                                            <strong>Tone Notebook </strong>
                                        </h1>
                                        {/* <h1 className="display-1 mt-0 bold"><strong>Tone Hub </strong></h1> */}
                                        <hr className="hr-short ms-1 mt-0 mb-3"></hr>
                                        <h4 className="mb-4">
                                            A free web-based application
                                            designed for your electric guitar
                                            sounds, keep track of your favourite
                                            amp settings with ease.
                                        </h4>
                                        <Link
                                            className="no-styling"
                                            to="/presets"
                                        >
                                            <button
                                                className="btn btn-primary me-3"
                                                data-tag-this="link_click"
                                                data-tag-details="Enter site from landing page"
                                            >
                                                Explore
                                            </button>
                                        </Link>
                                        <Link
                                            className="no-styling"
                                            to="/about"
                                        >
                                            <button
                                                className="btn btn-light"
                                                data-tag-this="link_click"
                                                data-tag-details="Go to about page from landing page"
                                            >
                                                Learn more
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="h-0 col-0 col-md-6 right-half"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(LandingPage);
