import React from "react";
import axiosConfig from "../../axios/axios";
import { auth } from "../../firebase/firebase.utils";
import { withRouter } from "react-router-dom";

import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";

import { PRESET_TYPES } from "../../utils/constants.utils";

class RatingSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rated: 0,
            localLike: 0,
            localDislike: 0,
            localSave: 0
        };
    }
    componentDidUpdate = (props) => {
        // console.log("Saved by current user");
        // console.log(this.props.presetDoc.savedByCurrentUser);
        // console.log("Local save")
        // console.log(this.state.localSave);
    };
    ratePreset = async (rating) => {
        try {
            if (!auth.currentUser) return this.props.history.push("/login");

            const presetId = this.props.presetId,
                userId = this.props.authState.user.uid;
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            const bodyParams = { rating: rating };
            const res = await axiosConfig.post(
                `presets/${presetId}/ratings/${userId}`,
                bodyParams,
                config
            );
            console.log(res);
        } catch (err) {
            console.log(err);
        }
    };

    deleteRating = async () => {
        try {
            const presetId = this.props.presetId,
                userId = this.props.authState.user.uid;
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            const res = await axiosConfig.delete(
                `presets/${presetId}/ratings/${userId}`,
                config
            );
            console.log(res);
            // this.props.refreshRatings();
        } catch (err) {
            console.log(err);
        }
    };

    savePreset = async () => {
        try {
            if (!auth.currentUser)
                return alert("You need to be signed in to save this preset.");
            const savedOnCloud = this.props.presetDoc.savedByCurrentUser;
            const savedLocally = this.state.localSave;
            if (savedOnCloud && savedLocally === 0) {
                this.setState({ localSave: -1 });
            } else if (!savedOnCloud && savedLocally === 0) {
                this.setState({ localSave: 1 });
            } else if (savedOnCloud && savedLocally === -1) {
                this.setState({ localSave: 0 });
            } else if (!savedOnCloud && savedLocally === 1) {
                this.setState({ localSave: 0 });
            }

            const presetId = this.props.presetId;
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            await axiosConfig.post(`presets/${presetId}/save`, {}, config);
            // const res = await axiosConfig.post(`presets/${presetId}/save`, {}, config);
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        this.getRatingStatus();
    }

    componentDidUpdate(prevProps) {
        // // If sign in status changes, also for auto sign in after refresh
        // if (this.props.user.uid && this.props.user.uid !== prevProps.user.uid ) {
        //     this.getRatingStatus();
        // }
        // if (this.props.likes !== prevProps.likes || this.props.dislikes !== prevProps.dislikes) {
        //     this.setState({
        //         localLike:0,
        //         localDislike: 0
        //     })
        // }
    }

    handleRateClick = async (newRating) => {
        // Remove rating
        if (this.state.rated === newRating) {
            await this.deleteRating();
            this.setState({ rated: 0 });
        }
        // Edit rating
        else {
            await this.ratePreset(newRating);
            this.setState({ rated: newRating });
        }
    };

    getRatingStatus = async () => {
        const userId = this.props.authState.user.uid;
        if (userId) {
            const presetId = this.props.presetId;
            const res = await axiosConfig.get(
                `presets/${presetId}/ratings/${userId}`
            );
            this.setState({ rated: res.data.rating });
            console.log(this.state);
        }
    };

    renderHeart = () => {
        if (
            (this.props.presetDoc.savedByCurrentUser &&
                this.state.localSave === -1) ||
            (!this.props.presetDoc.savedByCurrentUser &&
                this.state.localSave === 0)
        ) {
            return (
                <span className="text-extra-large text-secondary">
                    <i className="fa-regular fa-bookmark"></i>
                </span>
            );
        }
        return (
            <span className="text-extra-large text-primary">
                <i className="fa-solid fa-bookmark"></i>
            </span>
        );
    };
    renderBrokenHeart = () => {
        const savedOnCloud = this.props.presetDoc.savedByCurrentUser;
        const savedLocally = this.state.localSave;
        if (
            (savedLocally === 1 && !savedOnCloud) ||
            (savedLocally === 0 && savedOnCloud)
        ) {
            return (
                <span className="text-extra-large text-danger">
                    <i className="fas fa-heart-broken"></i>
                </span>
            );
        }
        return (
            <span className="text-extra-large text-secondary">
                <i className="fas fa-heart-broken"></i>
            </span>
        );
    };

    render() {
        const presetType = this.props.presetDoc.presetType;
        return (
            <div className="d-flex align-items-center justify-content-end">
                <div
                    className="hover-zoom"
                    onClick={this.savePreset}
                    data-tag-this="button_click"
                    data-tag-details="Like preset"
                >
                    {this.renderHeart()}
                </div>
                <div className="ms-2 me-4 text-secondary">
                    {this.props.presetDoc.savedNum + this.state.localSave}
                </div>
                {/* <div 
                className="hover-zoom" 
                onClick={() => this.handleRateClick(-1)}
                data-tag-this="button_click"
                data-tag-details="Dislike preset"
            >
                {this.renderBrokenHeart()}
            </div>
            <div className="ms-2 me-4 text-secondary">{this.props.dislikes + this.state.localDislike}</div> */}
                {presetType === PRESET_TYPES.thrii && (
                    <>
                        <div className="">
                            <span className="text-secondary text-extra-large">
                                <i className="fas fa-download"></i>
                            </span>
                        </div>
                        <div className="ms-2 me-4 text-secondary">
                            {this.props.downloads}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(RatingSection));
