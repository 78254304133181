// const colors = {
//     primary: "#267e7e",
//     secondary: "#826e4e",
//     dark: "#2d221a",
//     light: "#f5e4ca"
// }
const colors = {
    primary: "#4d9da1",
    secondary: "#826e4e",
    dark: "#3D4E54",
    light: "#f5e4ca",
    yamaha: "#FFF6E9"
};
export default colors;
