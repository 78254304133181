import React from "react";
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import RadialSeparators from "./radio-separator.component";
import "react-circular-progressbar/dist/styles.css";
import colors from "../../styles/colors";

class SchemaSelector extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render(props) {
        const { value, label } = this.props.attributes;
        return (
            <div className="d-flex flex-column bg-yamaha justify-content-center align-items-center p-1 w-100 rounded-3">
                <div className="m-3">
                    {/* <CircularProgressbar value={1} maxValue={1} text={value} /> */}
                    <CircularProgressbarWithChildren
                        value={100}
                        text={value}
                        strokeWidth={10}
                        styles={buildStyles({
                            strokeLinecap: "butt",
                            textSize: "12px"
                        })}
                    >
                        <RadialSeparators
                            count={12}
                            style={{
                                background: colors.yamaha,
                                width: "4px",
                                // This needs to be equal to props.strokeWidth
                                height: `${10}%`
                            }}
                        />
                    </CircularProgressbarWithChildren>
                </div>
                <strong className="text-center">{label}</strong>
                <label> Selector </label>
            </div>
        );
    }
}
export default SchemaSelector;
