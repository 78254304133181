import React from "react";
// import RangeViewer from '../range-viewer/range-viewer.component';
import color from "../../styles/colors";
import Switch from "react-switch";
import "./preset-viewer.styles.scss";
import {
    EFFECT_TYPES,
    REVERB_TYPES,
    ECHO_TYPES
} from "../../utils/constants.utils";

import {
    getAmpNameAndModeByValue,
    getCabNameByValue
} from "../../utils/functions.utils";

import Knob from "../preset-viewer-knob/preset-viewer-knob.component";

function CustomSwitch(props) {
    return (
        <Switch
            {...props}
            onChange={() => {}}
            onColor={color.primary}
            checkedIcon={false}
            uncheckedIcon={false}
            height={20}
            width={40}
            handleDiameter={20}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            className="react-switch"
        />
    );
}

const knobJustifyContent = "justify-content-center";
class PresetViewer extends React.Component {
    render() {
        const preset = this.props.preset;
        if (!preset) {
            return <strong>No preset</strong>;
        }
        try {
            console.log(preset);
            const {
                THRGroupCab,
                THRGroupAmp,
                THRGroupFX3EffectEcho,
                THRGroupGate,
                THRGroupFX1Compressor,
                THRGroupFX4EffectReverb,
                THRGroupFX2Effect
            } = preset.data.tone;

            const ampMode = getAmpNameAndModeByValue(
                THRGroupAmp["@asset"]
            ).mode;
            const ampName = getAmpNameAndModeByValue(
                THRGroupAmp["@asset"]
            ).name;
            const effectType = EFFECT_TYPES[THRGroupFX2Effect["@asset"]];
            const reverbType = REVERB_TYPES[THRGroupFX4EffectReverb["@asset"]];
            const echoType = ECHO_TYPES[THRGroupFX3EffectEcho["@asset"]];
            const cab = getCabNameByValue(THRGroupCab.SpkSimType);

            const rangeViewerClassName = "";

            return (
                <div className="p-5 bg-yamaha rounded-4">
                    <div className="preset-viewer-component">
                        <div className="">
                            <h3 className="text-center">
                                <strong>Yamaha THR-ii Settings</strong>
                            </h3>
                            <hr />
                            <div className="row aligh-items-center">
                                <div className="col-12 col-xl-4">
                                    <ul className="nav nav-pills align-items-center justify-content-center">
                                        <li className="nav-item">
                                            <div
                                                className={`nav-link ${
                                                    ampMode === "Modern" &&
                                                    "active"
                                                }`}
                                            >
                                                Modern
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div
                                                className={`nav-link ${
                                                    ampMode === "Bontique" &&
                                                    "active"
                                                }`}
                                            >
                                                Bontique
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <div
                                                className={`nav-link ${
                                                    ampMode === "Classic" &&
                                                    "active"
                                                }`}
                                            >
                                                Classic
                                            </div>
                                        </li>
                                    </ul>
                                    <hr />
                                    <div className="row">
                                        <div className="my-2 col-12 col-md-6 col-xl-12">
                                            <strong>Amp</strong>
                                            <select className="form-select my-2">
                                                <option
                                                    value={
                                                        ampName === "Special"
                                                    }
                                                >
                                                    Special
                                                </option>
                                                <option
                                                    value={
                                                        ampName === "Hi Gain"
                                                    }
                                                >
                                                    High Gain
                                                </option>
                                                <option
                                                    value={ampName === "Lead"}
                                                >
                                                    Lead
                                                </option>
                                                <option
                                                    value={ampName === "Crunch"}
                                                >
                                                    Crunch
                                                </option>
                                                <option
                                                    value={ampName === "Clean"}
                                                >
                                                    Clean
                                                </option>
                                            </select>
                                        </div>
                                        <div className="my-2 col-12 col-md-6 col-xl-12">
                                            <strong>Cabinet</strong>
                                            <select className="form-select my-2">
                                                <option>{cab}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-8 d-flex justify-content-evenly my-3 flex-wrap">
                                    <Knob
                                        size={72}
                                        label="Gain"
                                        value={THRGroupAmp.Drive}
                                        readOnly
                                    />
                                    <Knob
                                        size={72}
                                        label="Master"
                                        value={THRGroupAmp.Master}
                                        readOnly
                                    />
                                    <br className="w-100 d-md-none" />
                                    <Knob
                                        size={72}
                                        label="Bass"
                                        value={THRGroupAmp.Bass}
                                        readOnly
                                    />
                                    <Knob
                                        size={72}
                                        label="Mid"
                                        value={THRGroupAmp.Mid}
                                        readOnly
                                    />
                                    <Knob
                                        size={72}
                                        label="Treble"
                                        value={THRGroupAmp.Treble}
                                        readOnly
                                    />
                                </div>
                            </div>
                            <hr />
                        </div>

                        <div className="row ">
                            <div className="col-md-6 col-xl-3 my-4 border-md-end">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h3 className="d-inline me-2 mb-0">
                                        Noise Gate
                                    </h3>
                                    <CustomSwitch
                                        checked={THRGroupGate["@enabled"]}
                                    />
                                </div>
                                <div
                                    className={`d-flex ${knobJustifyContent} ${
                                        !THRGroupGate["@enabled"] &&
                                        "opacity-50"
                                    }`}
                                >
                                    <Knob
                                        label="Decay"
                                        value={THRGroupGate.Decay}
                                    />
                                    <Knob
                                        max={0}
                                        min={-96}
                                        label="Threshold"
                                        value={THRGroupGate.Thresh}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3 my-4 border-xl-end">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h3 className="d-inline me-2 mb-0">
                                        Compressor
                                    </h3>
                                    <CustomSwitch
                                        checked={
                                            THRGroupFX1Compressor["@enabled"]
                                        }
                                    />
                                </div>
                                <div
                                    className={`d-flex ${knobJustifyContent} ${
                                        !THRGroupFX1Compressor["@enabled"] &&
                                        "opacity-50"
                                    }`}
                                >
                                    <Knob
                                        label="Level"
                                        value={THRGroupFX1Compressor.Level}
                                    />
                                    {preset.version === 5 && (
                                        <Knob
                                            label="Sustain"
                                            value={
                                                THRGroupFX1Compressor.Sustain
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-6 my-4">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h3 className="d-inline me-2 mb-0">Echo</h3>
                                    <span className="opacity-50 me-2">
                                        - {echoType}
                                    </span>
                                    <CustomSwitch
                                        checked={
                                            THRGroupFX3EffectEcho["@enabled"]
                                        }
                                    />
                                </div>
                                <div
                                    className={`d-flex ${knobJustifyContent} flex-wrap ${
                                        !THRGroupFX3EffectEcho["@enabled"] &&
                                        "opacity-50"
                                    }`}
                                >
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Time"
                                        value={THRGroupFX3EffectEcho.Time}
                                    />
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Feedback"
                                        value={THRGroupFX3EffectEcho.Feedback}
                                    />
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Bass"
                                        value={THRGroupFX3EffectEcho.Bass}
                                    />
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Treble"
                                        value={THRGroupFX3EffectEcho.Treble}
                                    />
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Mix"
                                        value={THRGroupFX3EffectEcho["@wetDry"]}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-5 my-4 border-xl-end">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h3 className="d-inline me-2 mb-0">
                                        Reverb
                                    </h3>
                                    <span className="opacity-50 me-2">
                                        - {reverbType}
                                    </span>
                                    <CustomSwitch
                                        checked={
                                            THRGroupFX4EffectReverb["@enabled"]
                                        }
                                    />
                                </div>
                                <div
                                    className={`d-flex ${knobJustifyContent} ${
                                        !THRGroupFX4EffectReverb["@enabled"] &&
                                        "opacity-50"
                                    }`}
                                >
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Tone"
                                        value={THRGroupFX4EffectReverb.Tone}
                                    />
                                    {/* Only spring reverd has reverb(time) */}
                                    {THRGroupFX4EffectReverb.Time && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Reverb"
                                            value={THRGroupFX4EffectReverb.Time}
                                        />
                                    )}
                                    {/* Spring reverd does not have pre delay */}
                                    {THRGroupFX4EffectReverb.PreDelay && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="PreDelay"
                                            value={
                                                THRGroupFX4EffectReverb.PreDelay
                                            }
                                        />
                                    )}
                                    {/* Spring reverd does not have decay */}
                                    {THRGroupFX4EffectReverb.Decay && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Decay"
                                            value={
                                                THRGroupFX4EffectReverb.Decay
                                            }
                                        />
                                    )}
                                    <Knob
                                        className={rangeViewerClassName}
                                        label="Mix"
                                        value={
                                            THRGroupFX4EffectReverb["@wetDry"]
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-7 my-4">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <h3 className="d-inline me-2 mb-0">
                                        Effect
                                    </h3>
                                    <span className="opacity-50 me-2">
                                        - {effectType}
                                    </span>

                                    {/* <select className="form-select mx-2 w-auto">
                                {Object.keys(EFFECT_TYPES).map((key) => {
                                    return <option value={key}>{EFFECT_TYPES[key]}</option>;
                                })}
                            </select> */}
                                    <CustomSwitch
                                        checked={THRGroupFX2Effect["@enabled"]}
                                    />
                                </div>
                                <div
                                    className={`d-flex ${knobJustifyContent} flex-wrap ${
                                        !THRGroupFX2Effect["@enabled"] &&
                                        "opacity-50"
                                    }`}
                                >
                                    {THRGroupFX2Effect.Depth && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Depth"
                                            value={THRGroupFX2Effect.Depth}
                                        />
                                    )}
                                    {THRGroupFX2Effect.Feedback && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Feedback"
                                            value={THRGroupFX2Effect.Feedback}
                                        />
                                    )}
                                    {THRGroupFX2Effect.Freq && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Freq/Spd"
                                            value={THRGroupFX2Effect.Freq}
                                        />
                                    )}
                                    {THRGroupFX2Effect.Pre && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="PreDelay"
                                            value={THRGroupFX2Effect.Pre}
                                        />
                                    )}
                                    {THRGroupFX2Effect["@wetDry"] && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Mix"
                                            value={THRGroupFX2Effect["@wetDry"]}
                                        />
                                    )}
                                    {THRGroupFX2Effect.Speed && (
                                        <Knob
                                            className={rangeViewerClassName}
                                            label="Speed"
                                            value={THRGroupFX2Effect.Speed}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } catch (err) {
            // if (!preset || !preset.data) {
            return (
                <div>
                    <strong className="text-danger">
                        Error displaying preset, file is corrupted.
                    </strong>
                </div>
            );
            // }
        }
    }
}

export default PresetViewer;
