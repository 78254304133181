import axios from "axios";

const baseURL =
    window.location.hostname === "localhost"
        ? "http://localhost:5001/tonenotebookco/us-central1/api"
        : "https://us-central1-tonenotebookco.cloudfunctions.net/api";
const instance = axios.create({
    baseURL
});

export default instance;
