import React from "react";
import firebase, { firestore as db } from "../../firebase/firebase.utils";
import { connect } from "react-redux";
import { selectAuth } from "../../redux/user/user.slice";
import { withRouter } from "react-router-dom";
import Spinner from "../spinner/spinner.component";
import TextareaAutosize from "react-textarea-autosize";

class CommentSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: "",
            rating: 0,
            impressions: [],
            loading: false
        };
    }

    componentDidMount() {
        this.listImpressions();
    }

    submitImpression = async (e) => {
        e.preventDefault();
        if (!this.props.user.uid)
            return alert("Please login to make a comment.");
        // if (!this.state.rating) return alert("Please rate the preset.");
        this.setState({ loading: true });
        try {
            // Make a comment
            const comment = this.state.comment;

            if (comment)
                await db
                    .collection("tone_profiles_db")
                    .doc(this.props.presetId)
                    .collection("impressions")
                    .add({
                        // rating: this.state.rating,
                        comment: comment,
                        userId: this.props.user.uid,
                        userName: this.props.user.displayName,
                        createdAt: firebase.firestore.Timestamp.now()
                    });
            await this.listImpressions();
            this.setState({ comment: "" });
            // this.setState({rating: 0, comment: ""})
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
    };

    listImpressions = async () => {
        const presetImpressionsRatingsRef = db
            .collection("tone_profiles_db")
            .doc(this.props.presetId)
            .collection("impressions")
            .orderBy("createdAt", "desc")
            .limit(32);
        const snapshots = await presetImpressionsRatingsRef.get();
        const impressions = [];
        snapshots.forEach((item) => {
            const impressionObj = item.data();
            impressionObj.id = item.id;
            impressions.push(impressionObj);
        });
        this.setState({ impressions: impressions });
    };

    deleteImpression = async (impression) => {
        if (impression.userId !== this.props.user.uid) {
            return alert("You don't have permission to delete this comment.");
        }
        try {
            const impressionRef = db
                .collection("tone_profiles_db")
                .doc(this.props.presetId)
                .collection("impressions")
                .doc(impression.id);
            await impressionRef.delete();
            this.listImpressions();
        } catch (err) {
            console.log(err);
            alert("Something went wrong, please refresh and try again");
        }
    };

    render() {
        return (
            <div>
                <div className="my-5">
                    <h2>Comments</h2>
                    <hr />
                    <form className="my-3" onSubmit={this.submitImpression}>
                        <TextareaAutosize
                            className="form-control my-3 bg-light border-0"
                            type="text"
                            id="commentTextarea"
                            name="comment"
                            placeholder="Leave a public comment..."
                            value={this.state.comment}
                            onChange={(e) =>
                                this.setState({ comment: e.target.value })
                            }
                        />
                        <div className="text-end">
                            {this.state.loading ? (
                                <Spinner />
                            ) : (
                                <button
                                    className="btn btn-outline-primary"
                                    data-tag-this="button_click"
                                    data-tag-details="Submit comment"
                                >
                                    Submit comment
                                </button>
                            )}
                        </div>
                    </form>
                    <hr />
                    {!this.state.impressions.length ? (
                        <div className="mb-5 pb-5">
                            No comments yet... Be the first one to comment
                        </div>
                    ) : (
                        this.state.impressions.map((impression, i) => {
                            return (
                                <div key={i} className="my-3">
                                    <div>
                                        <span className="text-large">
                                            {impression.userName}{" "}
                                        </span>
                                        commented on{" "}
                                        {impression.createdAt
                                            .toDate()
                                            .toDateString()}
                                    </div>
                                    <p
                                        style={{ whiteSpace: "pre-wrap" }}
                                        className="my-3"
                                    >
                                        {impression.comment}
                                    </p>
                                    {impression.userId ===
                                        this.props.user.uid && (
                                        <button
                                            className="btn btn-danger"
                                            onClick={() =>
                                                this.deleteImpression(
                                                    impression
                                                )
                                            }
                                            data-tag-this="button_click"
                                            data-tag-details="Delete comment"
                                        >
                                            Delete comment
                                        </button>
                                    )}
                                    <hr></hr>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(CommentSection));
