import React from "react";
import { Link } from "react-router-dom";
import AuthSection from "../auth-section/auth-section.component";
import { ReactComponent as Logo } from "../../img/logo-4-text.svg";
import "./navbar.styles.scss";
import Hamburger from "hamburger-react";

import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }
    closeNav = () => {
        setTimeout(this.setState({ open: false }), 200);
    };
    renderNavLinks = (className, labelClassName, detailsClassName) => {
        const navLinks = [
            {
                to: "/presets",
                label: "Explore",
                details: "Search and filter presets",
                taggingDetails: "Navbar - Go to /presets"
            },
            {
                to: "/presets/create",
                label: "Share",
                details: "Upload and share",
                taggingDetails: "Navbar - Go to /presets/create"
            },
            // {to: "/preview-thr-ii", label: "Preview", details: "Visualize THR-ii preset", taggingDetails: "Navbar - Go to /preview-thr-ii"},
            {
                to: "/about",
                label: "About",
                details: "Learn more about us",
                taggingDetails: "Navbar - Go to /about"
            },
            {
                to: "/contact",
                label: "Contact",
                details: "Get in touch",
                taggingDetails: "Navbar - Go to /contact"
            }
        ];
        return navLinks.map(({ to, label, details, taggingDetails }) => {
            return (
                <div
                    className={className}
                    data-tag-this="link_click"
                    data-tag-details={taggingDetails}
                    key={to}
                >
                    <Link
                        className="no-styling"
                        key={to}
                        to={to}
                        onClick={() =>
                            setTimeout(this.setState({ open: false }), 200)
                        }
                    >
                        <div className={labelClassName}>{label}</div>
                        <div className={detailsClassName}>{details}</div>
                    </Link>
                </div>
            );
        });
    };
    render() {
        const className = "mx-2 no-styling ";
        const labelClassName = "hover-highlight";
        const detailsClassName = "text-size-75 opacity-50 d-none";
        const classNameMobile = "nav-link no-styling text-center my-0";
        const labelClassNameMobile =
            "text-large-150 hover-highlight text-700 mb-0 pb-0 ";
        const detailsClassNameMobile = "opacity-50";

        const myProfilePageTitle = "My Profile";
        const myProfilePageDetails = "View and manage my presets";

        return (
            <nav className="bg-dark fixed-top" id="navbar">
                <div
                    id="navbar-topbar"
                    className="container align-items-center bg-dark d-flex justify-content-between py-2"
                >
                    <a
                        className="navbar-brand no-styling text-light py-0"
                        href="/"
                    >
                        <Logo
                            className=""
                            fill="#fff"
                            height="36"
                            width="72"
                            viewBox="0 0 1547 792"
                        />
                        {/* <Logo  className="me-2" fill="#00b9b9" height="36" width="72" viewBox="0 0 1547 826"/> */}
                    </a>
                    <div
                        id="navbar-content"
                        className={this.state.open ? "bg-dark open" : "bg-dark"}
                    >
                        <div
                            className="text-light"
                            id="navbar-content-items"
                            style={{ flexGrow: 1 }}
                        >
                            {this.renderNavLinks(
                                className,
                                labelClassName,
                                detailsClassName
                            )}
                            {/* My presets option on navbar */}
                            {this.props.authState.isSignedIn && (
                                <div
                                    className={className}
                                    data-tag-this="link_click"
                                    data-tag-details="Click on my presets on navbar"
                                >
                                    <Link
                                        className="no-styling"
                                        to={"/profile/saved"}
                                        onClick={() =>
                                            setTimeout(
                                                this.setState({ open: false }),
                                                200
                                            )
                                        }
                                    >
                                        <div className={labelClassName}>
                                            {myProfilePageTitle}
                                        </div>
                                        <div className={detailsClassName}>
                                            {myProfilePageDetails}
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                        <AuthSection closeNav={this.closeNav} />
                    </div>
                    <div id="navbar-button">
                        <Hamburger
                            color="#eee"
                            toggled={this.state.open}
                            toggle={() =>
                                this.setState({ open: !this.state.open })
                            }
                        />
                    </div>
                </div>
                <div
                    id="navbar-content-mobile"
                    className={this.state.open ? "bg-dark show" : "bg-dark"}
                >
                    {this.renderNavLinks(
                        classNameMobile,
                        labelClassNameMobile,
                        detailsClassNameMobile
                    )}
                    {this.props.authState.isSignedIn && (
                        <div
                            className={classNameMobile}
                            data-tag-this="link_click"
                            data-tag-details="Click on my presets on navbar"
                        >
                            <Link
                                className="no-styling"
                                to={"/profile/saved"}
                                onClick={() =>
                                    setTimeout(
                                        this.setState({ open: false }),
                                        200
                                    )
                                }
                            >
                                <div className={labelClassNameMobile}>
                                    {myProfilePageTitle}
                                </div>
                                <div className={detailsClassNameMobile}>
                                    {myProfilePageDetails}
                                </div>
                            </Link>
                        </div>
                    )}
                    <AuthSection mobile={true} closeNav={this.closeNav} />
                </div>
            </nav>
        );
    }
}
const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(Nav);
