import React from "react";

class InputSelect extends React.Component {
    render() {
        const options = this.props.options.length ? this.props.options : [null];
        let selectedIndex;
        let value = this.props.value;
        const onChange = this.props.onChange;
        const name = this.props.name;
        if (!value) {
            value = options[selectedIndex];
        }
        const label = this.props.label;
        return (
            <div className="my-2">
                {label ? <label className="form-label">{label}</label> : null}
                <select
                    className="form-select"
                    value={value}
                    onChange={onChange}
                    name={name}
                >
                    {options.map((option, index) => {
                        return (
                            <option value={option} key={option + "-" + index}>
                                {option}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}

export default InputSelect;
