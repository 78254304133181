import React from "react";
import Skeleton from "react-loading-skeleton";

export default function SkeletonLoader(props) {
    return [0, 1, 2].map((i) => (
        <div className="grid" key={i}>
            <div className="row text-large">
                <div className="col-8">
                    <Skeleton />
                </div>
                <div className="col-4">
                    <Skeleton />
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <Skeleton />
                </div>
                <div className="col-8">
                    <Skeleton />
                </div>
            </div>
            <hr />
        </div>
    ));
}
