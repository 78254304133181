import React from "react";
import ScrollToTop from "../scroll-to-top/scroll-to-top.component";

class Page extends React.Component {
    render() {
        return (
            <div className="container page-wrapper">
                <ScrollToTop />
                {this.props.children}
            </div>
        );
    }
}

export default Page;
