import React from "react";
import {
    SUPPORTED_BRANDS,
    SUPPORTED_MODELS
} from "../../utils/constants.utils";
import InputReactSelect from "../../components/input-react-select/input-react-select.component";
import SchemaAttributeEditor from "../schema-attributes-editor/schema-attributes-editor.component";
// import { auth } from "../../firebase/firebase.utils";
import axiosConfig from "../../axios/axios";
import Spinner from "../spinner/spinner.component";
// import Tooltip from "../tooltip/tooltip.component";

const supportedBrandsOptions = SUPPORTED_BRANDS.map((item) => {
    return { value: item, label: item };
});
const supportedModelOptions = SUPPORTED_MODELS[SUPPORTED_BRANDS[0]].map(
    (item) => {
        return { value: item, label: item };
    }
);
const INITIAL_STATE = {
    modelOptions: supportedModelOptions,
    schema: {
        brand: SUPPORTED_BRANDS[0],
        model: SUPPORTED_MODELS[SUPPORTED_BRANDS[0]][0]
    },
    customSchema: false,
    downloadedSchema: null,
    searchingSchema: false
};

class SchemaBuilder extends React.Component {
    constructor(props) {
        super(props);
        INITIAL_STATE.customSchema = this.props.createSchemaMode;
        this.state = INITIAL_STATE;
        this.attributes = [];
    }

    componentDidMount = () => {
        this.onBrandSelection({ value: this.state.schema.brand });
    };

    componentDidUpdate = (prevProps) => {
        // Call onSchemaUpdate to update the schema saved on upper level
        const schema = { ...this.state.schema };
        schema.attributes = this.attributes;
        schema.isCustom = this.state.customSchema;
        this.props.onSchemaUpdate(schema);
    };

    onSchemaAttributeUpdate = (newAttributes) => {
        const schema = { ...this.state.schema };
        this.attributes = newAttributes;
        schema.attributes = newAttributes;
        schema.isCustom = this.state.customSchema;
        this.props.onSchemaUpdate(schema);
    };

    onBrandSelection = (event) => {
        const brand = event.value;
        let schema = { ...this.state.schema };
        schema.brand = brand;
        const models = SUPPORTED_MODELS[brand].map((item) => {
            return { value: item, label: item };
        });
        this.setState({ modelOptions: models, schema }, () =>
            this.onModelSelection(models[0])
        );
    };

    onModelSelection = (event) => {
        const model = event.value;
        let schema = { ...this.state.schema };
        schema.model = model;
        this.setState({ model, schema }, this.onSchemaSearch);
    };

    onSchemaSearch = () => {
        try {
            this.setState({ searchingSchema: true }, async () => {
                const { brand, model } = this.state.schema;
                const res = await axiosConfig.get(
                    `/schemas?brand=${brand}&model=${model}`
                );
                console.log(res);
                if (res.data.found) {
                    const { schema } = res.data;
                    if (!this.props.createSchemaMode) {
                        this.setState({
                            downloadedSchema: schema,
                            customSchema: false
                        });
                    } else {
                        this.setState({
                            downloadedSchema: schema,
                            customSchema: true
                        });
                    }
                } else {
                    this.setState({
                        downloadedSchema: null,
                        customSchema: true
                    });
                }
                this.setState({
                    searchingSchema: false
                });
            });
        } catch (err) {
            console.log(err);
            this.setState({ searchingSchema: false });
            alert("Something went wrong, please refresh and try again");
        }
    };

    render = () => {
        return (
            <div className="my-3">
                <b>Select amp model</b>
                <div className="d-flex justify-content-center align-items-end my-3">
                    <div className="me-2 w-100">
                        <InputReactSelect
                            value={supportedBrandsOptions.find(
                                ({ value }) => value === this.state.schema.brand
                            )}
                            options={supportedBrandsOptions}
                            onChange={this.onBrandSelection}
                            label="Manufacture"
                        />
                    </div>
                    <div className="ms-2 w-100">
                        <InputReactSelect
                            value={this.state.modelOptions.find(
                                ({ value }) => value === this.state.schema.model
                            )}
                            options={this.state.modelOptions}
                            onChange={this.onModelSelection}
                            label="Model"
                        />
                    </div>
                </div>
                {!this.props.createSchemaMode ? (
                    <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${
                                        !this.state.customSchema ? "active" : ""
                                    } ${
                                        !this.state.downloadedSchema
                                            ? "disabled"
                                            : ""
                                    }`}
                                    aria-disabled={!this.state.downloadedSchema}
                                    href="#"
                                    onClick={() =>
                                        this.setState({ customSchema: false })
                                    }
                                >
                                    <b>Create from ToneNotebook schema </b>
                                    <br />
                                    <span className="text-size-75">
                                        {!this.state.downloadedSchema
                                            ? "(Not yet supported for this amp)"
                                            : "Only need to edit values"}
                                    </span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${
                                        this.state.customSchema ? "active" : ""
                                    }`}
                                    href="#"
                                    tabindex="-1"
                                    onClick={() =>
                                        this.setState({ customSchema: true })
                                    }
                                >
                                    <b>Create from Custom schema</b>
                                    <br />
                                    <span className="text-size-75">
                                        Fully customizable
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </div>
                ) : null}
                {this.state.searchingSchema && <Spinner />}
                {!this.state.searchingSchema && // If not searching
                !this.state.customSchema ? ( // If not using custom schema
                    <SchemaAttributeEditor
                        onSchemaAttributeUpdate={this.onSchemaAttributeUpdate}
                        initialSchema={this.state.downloadedSchema}
                        limitedAccess={true}
                    />
                ) : (
                    <SchemaAttributeEditor
                        onSchemaAttributeUpdate={this.onSchemaAttributeUpdate}
                        initialSchema={this.state.downloadedSchema}
                        limitedAccess={false}
                    />
                )}

                {/* <button className="btn btn-primary" onClick={() => console.log(this.state)}>Log State</button> */}
            </div>
        );
    };
}

export default SchemaBuilder;
