import React from "react";
import { withRouter, Link, Route, Switch } from "react-router-dom";
import axiosConfig from "../../axios/axios";
import { connect } from "react-redux";
import { selectAuth } from "../../redux/user/user.slice";
import { auth } from "../../firebase/firebase.utils";
import PresetInfo from "../../components/preset-info/preset-info.component";
import Spinner from "../../components/spinner-preset-info/spinner-preset-info.component";
import { APP_NAME } from "../../utils/constants.utils";
import Skeleton from "react-loading-skeleton";
// import 'react-loading-skeleton/dist/skeleton.css';

class SavedPresetsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            savedPresets: [],
            userUploads: [],
            loading: true,
            isLoadingUser: true,
            userDoc: {
                description: null,
                displayName: null,
                socialMediaLink: null,
                username: null
            }
        };
    }
    componentDidMount = () => {
        document.title = APP_NAME + " | My Profile";
        if (auth.currentUser) {
            this.getDataFromApi();
            this.getUserPublicInfo();
        }
    };

    componentDidUpdate = (prevProps) => {
        // console.log(auth.currentUser);
        if (auth.currentUser) {
            console.log(prevProps);
            if (
                prevProps.authState.user.uid !==
                    this.props.authState.user.uid ||
                this.props.location.pathname !== prevProps.location.pathname
            ) {
                this.getDataFromApi();
                this.getUserPublicInfo();
            }
        } else if (!auth.currentUser) {
            this.props.history.push("/");
        }
    };

    getUserPublicInfo = async () => {
        this.setState({ isLoadingUser: true }, async () => {
            try {
                const { authState } = this.props;
                console.log(this.props);
                const res = await axiosConfig.get(
                    `/users/${authState.user.uid}`
                );
                this.setState({ userDoc: res.data.user, isLoadingUser: false });
                console.log(res.data.user);
            } catch (err) {
                console.log(err);
                this.setState({ isLoadingUser: false });
            }
        });
    };

    getDataFromApi = () => {
        const pathname = this.props.location.pathname;
        const currPage = pathname.split("/").at(-1);
        if (currPage === "saved") {
            this.getSavedPresets();
        } else if (currPage === "uploads") {
            this.getUserUploads();
        }
    };

    getSavedPresets = async () => {
        this.setState({ loading: true }, async () => {
            try {
                const authToken = await auth.currentUser.getIdToken();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                };
                const result = await axiosConfig.get(`/presets/saved`, config);
                this.setState({
                    savedPresets: result.data.savedPresets,
                    loading: false
                });
            } catch (err) {
                console.log(err);
                this.setState({ loading: false });
            }
        });
    };

    getUserUploads = async () => {
        this.setState({ loading: true }, async () => {
            try {
                const authToken = await auth.currentUser.getIdToken();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                };
                const result = await axiosConfig.get(
                    `/presets/uploads`,
                    config
                );
                this.setState({
                    userUploads: result.data.userUploads,
                    loading: false
                });
            } catch (err) {
                console.log(err);
                this.setState({ loading: false });
            }
        });
    };

    render() {
        const path = this.props.match.path;
        const pathname = this.props.location.pathname;
        const { username, displayName, description } = this.state.userDoc;
        // console.log(path);
        return (
            <div className="page-wrapper">
                <div className="container">
                    {this.state.userDoc ? (
                        <div>
                            <h1 className=" text-700 mb-0 mt-3">
                                {displayName === null ? (
                                    <Skeleton />
                                ) : (
                                    displayName
                                )}
                            </h1>
                            <div className="text-medium ">
                                {" "}
                                {username === null ? (
                                    <Skeleton />
                                ) : (
                                    "@" + username
                                )}{" "}
                            </div>

                            <div className="my-3">
                                {description === null ? (
                                    <Skeleton count={5} />
                                ) : (
                                    <div className="my-3">
                                        <h3>Description</h3>
                                        <p> {description || "--"}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <h3>User presets</h3>

                    <ul className="nav nav-tabs mt-3">
                        <li className="nav-item">
                            <Link to={`${path}/saved`}>
                                <span
                                    className={`nav-link ${
                                        pathname.split("/").at(-1) === "saved"
                                            ? "active"
                                            : ""
                                    }`}
                                    aria-current="page"
                                    href="#"
                                >
                                    Saved
                                </span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`${path}/uploads`}>
                                <span
                                    className={`nav-link ${
                                        pathname.split("/").at(-1) === "uploads"
                                            ? "active"
                                            : ""
                                    }`}
                                    aria-current="page"
                                    href="#"
                                >
                                    Uploads
                                </span>
                            </Link>
                        </li>
                    </ul>
                    <div className="my-3">
                        <Switch>
                            <Route path={`${path}/saved`}>
                                {this.state.loading ? (
                                    <div className="text-center">
                                        <Spinner />
                                    </div>
                                ) : this.state.savedPresets.length ? (
                                    this.state.savedPresets.map(
                                        (presetItem) => {
                                            return (
                                                <div key={presetItem.presetId}>
                                                    <PresetInfo
                                                        presetInfo={presetItem}
                                                    />
                                                </div>
                                            );
                                        }
                                    )
                                ) : (
                                    <div>
                                        You list is empty, start exploring{" "}
                                        <span>
                                            <Link to="/presets">presets</Link>
                                        </span>
                                        .
                                    </div>
                                )}
                            </Route>
                            <Route path={`${path}/uploads`}>
                                {this.state.loading ? (
                                    <div className="text-center">
                                        <Spinner />
                                    </div>
                                ) : this.state.userUploads.length ? (
                                    this.state.userUploads.map((presetItem) => {
                                        return (
                                            <div key={presetItem.presetId}>
                                                <PresetInfo
                                                    presetInfo={presetItem}
                                                />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>
                                        You list is empty, start exploring{" "}
                                        <span>
                                            <Link to="/presets">presets</Link>
                                        </span>
                                        .
                                    </div>
                                )}
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(SavedPresetsPage));
