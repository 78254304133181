import axiosConfig from "../axios/axios";

// export const createUserProfileDocument = async (userAuth, additionalData) => {
//     if (!userAuth) return;
//     try {
//         const bodyParams = {
//             userAuth: userAuth,
//             additionalData: additionalData
//         };
//         const res = await axiosConfig.post("/users", bodyParams);
//         return res.data;
//     } catch (error) {
//         if (error.response) {
//             // The request was made and the server responded with a status code
//             // that falls out of the range of 2xx
//             console.log(error.response.data);
//             console.log(error.response.status);
//             console.log(error.response.headers);
//         } else if (error.request) {
//             // The request was made but no response was received
//             // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//             // http.ClientRequest in node.js
//             console.log(error.request);
//         } else {
//             // Something happened in setting up the request that triggered an Error
//             console.log("Error", error.message);
//         }
//         console.log(error.config);
//         alert("Something went wrong");
//     }
// };
export const createUserProfileDocument = async (userAuth, additionalData) => {
    const bodyParams = {
        userAuth: userAuth,
        additionalData: additionalData
    };
    const res = await axiosConfig.post("/users", bodyParams);
    return res.data;
};

export const getUserInfo = async (authToken, userId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${authToken}`
        }
    };
    const res = await axiosConfig.get(`/users/${userId}`, config);
    return res.data;
};
