export const ECHO_TYPES = {
    TapeEcho: "Tape Echo",
    L6DigitalDelay: "Digital Delay"
};

export const REVERB_TYPES = {
    ReallyLargeHall: "Hall",
    SmallRoom1: "Room",
    StandardSpring: "Spring",
    LargePlate1: "Plate"
};

export const EFFECT_TYPES = {
    StereoSquareChorus: "Chorus",
    Phaser: "Phaser",
    L6Flanger: "Flanger",
    BiasTremolo: "Tremolo"
};

export const PROD_HOSTNAME = "yamaha-thr.web.app";

export const APP_NAME = "ToneNotebook";

export const USER_AUTH_TYPE = {
    google: "GOOGLE",
    facebook: "FACEBOOK",
    email: "EMAIL"
};

export const SCHEMAS = {
    knob: {
        type: "KNOB",
        label: "Knob",
        value: 0.5,
        max: 10
    },
    selector: {
        type: "SELECTOR",
        label: "Selector",
        value: "Option1",
        options: ["Option1", "Option2", "Option3"]
    },
    switch: {
        type: "SWITCH",
        label: "Switch",
        value: true,
        labelPos: "ON",
        labelNeg: "OFF"
    }
};

export const SUPPORTED_MODELS = {
    Marshall: [
        "1959HW",
        "1974X",
        "2555X",
        "1987X",
        "JTM45 2245",
        "JCM800 2203",
        "JCM900 4100",
        "1962 'BLUESBREAKER",
        "AS50D",
        "MG10",
        "MG15",
        "MG15R",
        "MG15FX",
        "MG30FX",
        "MG50FX",
        "MS-2",
        "MS-4",
        "CODE25",
        "CODE50",
        "SC20C",
        "SC20H",
        "SV20C",
        "SV20H",
        "2525C",
        "2525H",
        "Origin20C",
        "Origin20H",
        "Origin50C",
        "Origin50H",
        "DSL1C",
        "DSL1H",
        "DSL5C",
        "DSL20C",
        "DSL20H",
        "DSL40",
        "DSL100",
        "JVM205C",
        "JVM205H",
        "JVM210C",
        "JVM210H",
        "JVM215",
        "JVM410C",
        "JVM410H",
        "JCM2000 DSL",
        "JSM2000 TSL"
    ],
    Orange: ["Crush", "Terror", "Rocker 32s", "TremLord 30", "Rockerverb"],
    Fender: [
        "Blues Junior IV",
        "Mustang LT50",
        "Hot Rod DeVille 212 IV",
        "Hot Rod Deluxe IV",
        "Pro Junior IV",
        "Mustang LT25",
        "Mustang GTX50",
        "'65 Twin Reverb",
        "Tone Master Deluxe Reverb",
        "'65 Deluxe Reverb",
        "Blues Junior Lacquered Tweed",
        "'57 Custom Deluxe",
        "Champion 20",
        "Champion 100",
        "Mustang GTX100",
        "Acoustic 100",
        "Frontman 10G",
        "Acoustasonic 40",
        "Champion 40",
        "'68 Custom Deluxe Reverb",
        "Bassbreaker 15 Combo",
        "'68 Custom Princeton Reverb",
        "'59 Bassman LTD",
        "'57 Custom Champ",
        "Super-Sonic 22 Combo",
        "Acoustasonic 15",
        "Frontman 20G",
        "Champion 50XL",
        "Hot Rod Deluxe 112 Enclosure",
        "Mini Tonemaster Amplifier",
        "Mini '57 Twin-Amp",
        "Tone Master Super Reverb",
        "MD20 Mini Deluxe Amp",
        "Super-Sonic 22 Head",
        "GB Twin Reverb",
        "Bassbreaker 007 Combo",
        "Bassbreaker 15 Head",
        "'64 Custom Princeton Reverb",
        "'64 Custom Deluxe Reverb",
        "'65 Princeton Reverb",
        "Blues Deluxe Reissue",
        "Tone Master Twin Reverb",
        "'65 Super Reverb",
        "'68 Custom Twin Reverb",
        "'68 Custom Vibro Champ",
        "'68 Custom Pro Reverb",
        "Tonemaster Deluxe Reverb"
    ],
    Vox: ["AC4 Custom", "AC10 Custom", "AC15 Custom", "AC30 Custom"],
    Boss: [
        "Waza",
        "Nextone",
        "Katana Artist MkII 1x12",
        "Katana Bass",
        "Cube",
        "Acoustic Amp"
    ],
    Yamaha: ["THR10", "THR5", "THR5A", "THR10C", "THR10X", "THR10-ii"],
    "Mesa/Boogie": [
        "California Tweed",
        "Filmore",
        "Mark Series",
        "Rectifier",
        "Triple Crown",
        "Rosette 300",
        "Subway"
    ],
    Victory: [
        "V140 Duchess",
        "VC35 The Copper",
        "V30 The Jack MKII",
        "V130 The Super Jack",
        "Sheriff 22",
        "Sheriff 44",
        "VX The Kraken",
        "VX100 The Super Kraken",
        "DP40 The Duchess",
        "RK100 Custom Limited Edition",
        "RK50 Richie Kotzen",
        "V50 The Earl"
    ],
    Peavey: [
        "6505",
        "Classic",
        "invective",
        "Vypyr",
        "TransTube",
        "Budda Guitar Amps"
    ],
    Randall: [
        "KH103 Kirk Hammett",
        "KH120RHS Kirk Hammett",
        "RD100H",
        "RD1H",
        "RD20H",
        "RD45H",
        "RD5h",
        "RG1003H",
        "RG1503H",
        "RG3003H",
        "Thrasher",
        "Thrasher 50"
    ],
    "Line 6": ["Catalyst", "Spider V MkII", "Powercab"],
    Roland: [
        "JC-120",
        "JC-40",
        "JC-22",
        "Blues Cube Hot",
        "Blue Cube Tour",
        "Blues Cube Artist212",
        "Blue Cube Artist",
        "Blue Cube Stage",
        "Blues Cube Cabinet410"
    ],
    EVH: ["EVH Head", "EVH Combos", "EVH Cabinets"],
    Blackstar: [
        "Studio 10 KT88",
        "Studio 10 EL34",
        "Studio 10 6L6",
        "Silverline Deluxe",
        "HT-20R MkII"
    ],
    Laney: [
        "SuperGroup",
        "Lionheart",
        "Ironheart",
        "GS Cabinets",
        "FRFR",
        "Cub-Super",
        "LX",
        "LG",
        "Mini"
    ],
    Bugera: ["Infinium Series", "Ultrabase Series"],
    Other: ["Other"]
};

export const PRESET_TYPES = {
    thrii: "THRII",
    schema: "SCHEMA"
};

export const VISIBILITY_OPTIONS_JSON = {
    public: "PUBLIC",
    private: "PRIVATE",
    unlisted: "UNLISTED"
};
export const VISIBILITY_OPTIONS = Object.values(VISIBILITY_OPTIONS_JSON);

export const SUPPORTED_BRANDS = Object.keys(SUPPORTED_MODELS);

export const socialMediaList = [
    {
        icon: <i className="fa-brands fa-facebook"></i>,
        link: "https://www.facebook.com/ToneNotebook-102625579169858",
        text: "Facebook"
    },
    {
        icon: <i className="fa-brands fa-instagram"></i>,
        link: "https://instagram.com/tonenotebook/",
        text: "Instagram"
    },
    {
        icon: <i className="fa-brands fa-discord"></i>,
        link: "https://discord.gg/DbXqHkrNHs",
        text: "Discord"
    }
];
