import React from "react";
import { Link } from "react-router-dom";
// import Spinner from "../spinner/spinner.component";

class ProfilesList extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const presets = this.props.presets;
        console.log(presets);
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Downloads</th>
                        <th scope="col">Last Updated</th>
                    </tr>
                </thead>
                <tbody>
                    {presets.map((preset) => {
                        return (
                            <tr>
                                <td
                                    data-tag-this="link_click"
                                    data-tag-details="Go to a preset from user public profile page"
                                >
                                    <Link to={`/presets/${preset.presetId}`}>
                                        {preset.name}
                                    </Link>
                                </td>
                                <td>{preset.downloads}</td>
                                <td>
                                    {preset.lastUpdated.toDate().toDateString()}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}

export default ProfilesList;
