import React from "react";
import { APP_NAME } from "../../utils/constants.utils";
import "./about.styles.scss";
import img1 from "../../img/about-page/duncan-kidd-PzFyCvZa-hw-unsplash-min.jpg";
import img2 from "../../img/about-page/eric-krull-fi3_lDi3qPE-unsplash.jpg";
import img3 from "../../img/about-page/yvette-de-wit-NYrVisodQ2M-unsplash.jpg";
import img4 from "../../img/about-page/cookie-the-pom-gySMaocSdqs-unsplash.jpg";
import { Link } from "react-router-dom";
import ScrollToTopOnMount from "../../components/scroll-to-top/scroll-to-top.component";

class AboutPage extends React.Component {
    componentDidMount() {
        document.title = APP_NAME + " | About";
    }
    render() {
        const coreFeaturesCopy = [
            {
                title: "Keep track of your favourite guitar settings.",
                description:
                    "ToneNotebook provides a simple way for all guitarists to create and save their amp and pedal settings, the presets are saved on the cloud and will be accessible anywhere through your web browser.",
                icon: <i className="fa-solid fa-guitar"></i>,
                image: img1
            },
            {
                title: "Community-supported database for everyone.",
                description:
                    "Search for guitar tones based on your amp model and keywords through our community-based database, no signup is needed. Share your tone with the world after a simple sign-up.",
                icon: <i className="fa-solid fa-database"></i>,
                image: img2
            },
            {
                title: "Created by the community, designed for the community.",
                description:
                    "Designed and built by two software developers who are passionate about music and want to do what we can to simplify everything else so musicians can fully focus on their creativity.",
                icon: <i className="fa-solid fa-comments"></i>,
                image: img3
            },
            {
                title: "Free to use for everyone",
                description:
                    "Start using  with simplified sign up process, sign in with easy and secure third-party logins such as Facebook and Google.",
                icon: <i className="fa-solid fa-handshake"></i>,
                image: img4
            }
        ];

        const moreDetailsCopy = [
            {
                title: "Who we are",
                description:
                    "We are two indie software developers who are passionate about music and want to do what we can to simplify the creative process of musicians from all over the world."
            },
            {
                title: "How are we different",
                description:
                    "TonenoteBook is one of the first companies focused on building a community for guitar players to keep track of their guitar tones, share them, or find new tones through the community."
            },
            {
                title: "What our goal is",
                description:
                    "At ToneNotebook, we aim to create the biggest database that covers different styles of Amp settings for different types of music. We also aim to create a community for guitar players at all level through our social media and Discord platform. We are here to inspire and encourage new players and also connect veteran players to form a healthy and helpful community."
            }
        ];

        return (
            <div className="about-page">
                <ScrollToTopOnMount />
                <div className="page-wrapper bg-dark text-light pb-5">
                    <div className="container">
                        <h1 className="my-3 text-center">About ToneNotebook</h1>
                        <h4 className="text-center mx-md-5 my-3">
                            ToneNotebook is a free web-based application
                            designed for your electric guitar sounds, keep track
                            of your favourite amp settings with ease.
                        </h4>
                    </div>
                </div>
                <div className="container mb-5">
                    {coreFeaturesCopy.map(
                        ({ title, description, icon, image }, index) => {
                            return (
                                <div className="info-frame mb-4">
                                    <div
                                        className="info-frame-text"
                                        style={{
                                            order: index % 2 === 1 ? 1 : 3
                                        }}
                                    >
                                        <div className="text-large text-primary">
                                            {icon}
                                        </div>
                                        <h2>{title}</h2>
                                        <p>{description}</p>
                                    </div>
                                    <div
                                        className="info-frame-gap"
                                        style={{ order: 2 }}
                                    >
                                        {" "}
                                    </div>
                                    <div
                                        className="info-frame-img"
                                        style={{
                                            order: index % 2 === 1 ? 3 : 1
                                        }}
                                    >
                                        <div className="image-wrapper">
                                            <img
                                                className="w-100"
                                                src={image}
                                                alt="decoration"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>

                {/* <div className="row mt-5">
                        {coreFeaturesCopy.map((item, index) => {
                            return (
                                <div className="col-12 col-sm-6 col-lg-3" key={index}>
                                    <div className="mx-2">
                                        <div className="text-large text-primary">{item.icon}</div>
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div> */}
                <div className="py-5 bg-light">
                    <div className="my-3 d-sm-flex container">
                        {moreDetailsCopy.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="px-2"
                                    style={{ flex: "1 1" }}
                                >
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Link to="/presets">
                    <h3
                        className="bg-primary pb-2 pt-3 text-light text-center font-display"
                        data-tag-this="link_click"
                        data-tag-details="Click on the banner on about page"
                    >
                        Start using Tone notebook now{" "}
                        <i className="fa-solid fa-arrow-right"></i>
                    </h3>
                </Link>
            </div>
        );
    }
}

export default AboutPage;
