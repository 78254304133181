import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    signInWithGoogle,
    auth,
    firestore
} from "../../firebase/firebase.utils";
import { signInSuccessful } from "../../redux/user/user.slice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createUserProfileDocument } from "../../api/user.api";
import { dataLayerPush } from "../../utils/tagging.utils";
import { USER_AUTH_TYPE } from "../../utils/constants.utils";
import InputButton from "../../components/input-buttonwithloading/buttonwithloading.component";

function LoginPage() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [loadingGLogin, setLoadingGLogin] = useState(false); // Google login
    const [loadingPLogin, setLoadingPLogin] = useState(false); // Password login
    const history = useHistory();

    async function loginWithGoogle(e) {
        try {
            e.preventDefault();
            dataLayerPush("login", { details: "Login with Google" });
            setLoadingGLogin(true);
            const result = await signInWithGoogle();
            if (result) {
                const { displayName } = result.user;
                const authType = USER_AUTH_TYPE.google;
                const { userProfile } = await createUserProfileDocument(
                    result.user,
                    { displayName, authType }
                );
                const isAdmin = userProfile.isAdmin ? true : false;

                dispatch(
                    signInSuccessful({
                        displayName: result.user.displayName,
                        email: result.user.email,
                        uid: result.user.uid,
                        isAdmin: isAdmin
                    })
                );
                history.push("/");
            }
        } catch (err) {
            console.log(err);
            if (err.code !== "auth/popup-closed-by-user") {
                setErrorMsg("Something went wrong.");
            }
            setLoadingGLogin(false);
        }
    }

    // async function loginWithFacebook(e) {
    //     try {
    //         e.preventDefault();
    //         dataLayerPush("login", { details: "Login with Facebook" });
    //         setLoadingFLogin(true);
    //         const result = await signInWithFacebook();
    //         if (result) {
    //             const { displayName } = result.user;
    //             const authType = USER_AUTH_TYPE.facebook;
    //             const { userProfile } = await createUserProfileDocument(
    //                 result.user,
    //                 { displayName, authType }
    //             );
    //             const isAdmin = userProfile.user.isAdmin ? true : false;
    //             dispatch(
    //                 signInSuccessful({
    //                     displayName: result.user.displayName,
    //                     email: result.user.email,
    //                     uid: result.user.uid,
    //                     username: userProfile.username,
    //                     isAdmin: isAdmin
    //                 })
    //             );
    //             history.push("/");
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         if (err.code !== "auth/popup-closed-by-user") {
    //             setErrorMsg("Something went wrong.");
    //         }
    //         setLoadingFLogin(false);
    //     }
    // }

    async function submitLoginForm(e) {
        try {
            e.preventDefault();
            dataLayerPush("login", {
                details: "Login with email and password"
            });
            setErrorMsg("");
            setLoadingPLogin(true);
            const result = await auth.signInWithEmailAndPassword(
                email,
                password
            );
            if (result) {
                setEmail("");
                setPassword("");
                const { uid, email } = result.user;
                const userRef = await firestore
                    .collection("users")
                    .doc(uid)
                    .get();
                if (userRef.exists) {
                    const { displayName, username } = userRef.data();
                    const isAdmin = userRef.data().isAdmin ? true : false;
                    dispatch(
                        signInSuccessful({
                            uid,
                            email,
                            displayName,
                            username,
                            isAdmin
                        })
                    );
                    history.push("/", { flashMessage: "test" });
                }
            }
        } catch (err) {
            if (
                err.code === "auth/user-not-found" ||
                err.code === "auth/invalid-email" ||
                err.code === "auth/wrong-password"
            ) {
                setErrorMsg("Invalid login credentials, please try again.");
            } else {
                setErrorMsg(
                    "Something went wrong, please refresh and try again."
                );
            }
            console.log(err);
            setLoadingPLogin(false);
        }
    }

    return (
        <div className="container pt-3 d-flex align-items-center my-height-100">
            <div className="container-narrow">
                <h1 className="my-3">Sign in with email and password</h1>
                {errorMsg && (
                    <div className="bg-danger-light text-danger text-center p-2 my-3">
                        {" "}
                        {errorMsg}
                    </div>
                )}
                <form className="" onSubmit={submitLoginForm}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                            Email address
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="name@example.com"
                            required="True"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            Password
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Password"
                            required="True"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <InputButton
                        type="submit"
                        className="btn btn-primary me-3 w-100 my-3"
                        isloading={loadingPLogin}
                    >
                        Sign in with Email and Password
                    </InputButton>
                </form>
                <div className="row">
                    {/* <div className="col">
                        <InputButton
                            type="button"
                            className="btn bg-facebook text-white w-100"
                            isloading={loadingFLogin}
                            onClick={loginWithFacebook}
                        >
                            <i className="fab fa-facebook-f"></i>
                            <span className="ms-2">Login with Facebook</span>
                        </InputButton>
                    </div> */}
                    <div className="col">
                        {/* <button type="button" className="btn bg-google text-white w-100" onClick={loginWithGoogle}> 
                            <i className="fab fa-google"></i>
                            <span className="ms-2">Login with Google</span>
                        </button> */}

                        <InputButton
                            type="submit"
                            className="btn btn-danger text-white w-100"
                            isloading={loadingGLogin}
                            onClick={loginWithGoogle}
                        >
                            <i className="fab fa-google"></i>
                            <span className="ms-2">Login with Google</span>
                        </InputButton>
                    </div>
                </div>
                <br />
                <div
                    className="text-center"
                    data-tag-this="link_click"
                    data-tag-details="Go to sign up page from login page"
                >
                    <Link to="signup">
                        Don't have an account yet? Sign up for free!
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
