function getCabNameByValue(value) {
    const cabs = {
        14: "Bontique 1x12",
        13: "American 1x12",
        12: "California 1x12",
        11: "Yamaha 2x12",
        10: "Bontique 2x12",
        9: "British Blues 2x12",
        8: "British 2x12",
        7: "American 2x12",
        6: "Mods 4x12",
        5: "Juicy 4x12",
        4: "Fuel 4x12",
        3: "Vintage 4x12",
        2: "Brown 4x12",
        1: "American 4x12",
        0: "British 4x12"
    };
    const cabName = cabs[value];
    if (cabName) return cabName;
    else return "No cab found";
}
function getAmpNameAndModeByValue(value) {
    const amps = {
        THR10C_Deluxe: {
            mode: "Classic",
            name: "Clean"
        },
        THR10C_DC30: {
            mode: "Classic",
            name: "Crunch"
        },
        THR10_Lead: {
            mode: "Classic",
            name: "Lead"
        },
        THR10_Modern: {
            mode: "Classic",
            name: "Hi Gain"
        },
        THR10X_Brown1: {
            mode: "Classic",
            name: "Special"
        },
        THR10C_BJunior2: {
            mode: "Bontique",
            name: "Clean"
        },
        THR10C_Mini: {
            mode: "Bontique",
            name: "Crunch"
        },
        THR30_Blondie: {
            mode: "Bontique",
            name: "Lead"
        },
        THR30_FLead: {
            mode: "Bontique",
            name: "Hi Gain"
        },
        THR10X_South: {
            mode: "Bontique",
            name: "Special"
        },
        THR30_Carmen: {
            mode: "Modern",
            name: "Clean"
        },
        THR30_SR101: {
            mode: "Modern",
            name: "Crunch"
        },
        THR10_Brit: {
            mode: "Modern",
            name: "Lead"
        },
        THR10X_Brown2: {
            mode: "Modern",
            name: "Hi Gain"
        },
        THR30_Stealth: {
            mode: "Modern",
            name: "Special"
        }
    };
    const amp = amps[value];
    if (amp) return amp;
    else return { mode: "No record", name: "No record" };
}

export { getAmpNameAndModeByValue, getCabNameByValue };
