import React from "react";
import ScrollToTopOnMount from "../../../components/scroll-to-top/scroll-to-top.component";

class PrivacyPolicyPage extends React.Component {
    render() {
        return (
            <div className="page-wrapper container">
                <ScrollToTopOnMount />
                <h1>Privacy Policy</h1>
                <p id="ed3728be-0676-42c7-b662-4ff7c2ad775c" class="">
                    At Tone Notebook, accessible from tonenotebook.com, one of
                    our main priorities is the privacy of our visitors. This
                    Privacy Policy document contains types of information that
                    is collected and recorded by Tone Notebook and how we use
                    it.
                </p>
                <p id="9c30a001-43ea-413b-890e-50c82590e114" class="">
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                </p>
                <p id="63bea254-4464-4651-ba8e-792e4216a7fb" class="">
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in Tone
                    Notebook. This policy is not applicable to any information
                    collected offline or via channels other than this website.
                </p>
                <h2 id="34e56773-4586-4b66-9961-9cece0a3573c" class="">
                    Consent
                </h2>
                <p id="4ac5135b-85c4-45f1-bc0f-ee8bc7417a77" class="">
                    By using our website, you hereby consent to our Privacy
                    Policy and agree to its terms.
                </p>
                <h2 id="07b6177f-ab41-4b0d-bcbf-1d4c28ad1e82" class="">
                    Information we collect
                </h2>
                <p id="cfcb2740-d872-4ff8-aa44-95548bec250c" class="">
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information.
                </p>
                <p id="25a19fb1-4d08-4df3-b201-75b442beb55a" class="">
                    If you contact us directly, we may receive additional
                    information about you such as your name, email address,
                    phone number, the contents of the message and/or attachments
                    you may send us, and any other information you may choose to
                    provide.
                </p>
                <p id="d811597e-1f64-42b3-b5b4-4d6abc69c0d0" class="">
                    When you register for an Account, we may ask for your
                    contact information, including items such as name, company
                    name, address, email address, and telephone number.
                </p>
                <h2 id="786848ff-5854-4ebc-8c6a-04cbdbbed4df" class="">
                    How we use your information
                </h2>
                <p id="08e18af0-b7ab-455c-8619-d64de387c5f2" class="">
                    We use the information we collect in various ways, including
                    to:
                </p>
                <ul
                    id="2d945677-7cf8-41d6-b595-6ad34f13da30"
                    class="bulleted-list"
                >
                    <li>Provide, operate, and maintain our website</li>
                </ul>
                <ul
                    id="1ce530b0-435c-4f4d-acf2-e3531fb92092"
                    class="bulleted-list"
                >
                    <li>Improve, personalize, and expand our website</li>
                </ul>
                <ul
                    id="238a4d61-61e1-4ea0-810f-51b72278f86e"
                    class="bulleted-list"
                >
                    <li>Understand and analyze how you use our website</li>
                </ul>
                <ul
                    id="670364b3-637f-44a4-b711-8b418fe19651"
                    class="bulleted-list"
                >
                    <li>
                        Develop new products, services, features, and
                        functionality
                    </li>
                </ul>
                <ul
                    id="ae0f2dc1-94ca-4f3f-b3a6-e07e3184cc63"
                    class="bulleted-list"
                >
                    <li>
                        Communicate with you, either directly or through one of
                        our partners, including for customer service, to provide
                        you with updates and other information relating to the
                        website, and for marketing and promotional purposes
                    </li>
                </ul>
                <ul
                    id="a3ab4ca1-fad1-4ae4-a911-5e12cbbf6e30"
                    class="bulleted-list"
                >
                    <li>Send you emails</li>
                </ul>
                <ul
                    id="d71b4105-d790-4878-bac7-3420e8e8a481"
                    class="bulleted-list"
                >
                    <li>Find and prevent fraud</li>
                </ul>
                <h2 id="14dc595e-5336-49fc-bccf-3d45788b2928" class="">
                    Log Files
                </h2>
                <p id="be913a6a-9ccd-4d17-b8d1-6bc0ace0aa97" class="">
                    Tone Notebook follows a standard procedure of using log
                    files. These files log visitors when they visit websites.
                    All hosting companies do this and a part of hosting
                    services&#x27; analytics. The information collected by log
                    files include internet protocol (IP) addresses, browser
                    type, Internet Service Provider (ISP), date and time stamp,
                    referring/exit pages, and possibly the number of clicks.
                    These are not linked to any information that is personally
                    identifiable. The purpose of the information is for
                    analyzing trends, administering the site, tracking
                    users&#x27; movement on the website, and gathering
                    demographic information.
                </p>
                <h2 id="e7fc3eef-83f1-436c-8828-bc0b14572ebc" class="">
                    Cookies and Web Beacons
                </h2>
                <p id="27be8e01-e828-4bbd-acc0-dc8ef114f0a2" class="">
                    Like any other website, Tone Notebook uses
                    &#x27;cookies&#x27;. These cookies are used to store
                    information including visitors&#x27; preferences, and the
                    pages on the website that the visitor accessed or visited.
                    The information is used to optimize the users&#x27;
                    experience by customizing our web page content based on
                    visitors&#x27; browser type and/or other information.
                </p>
                <h2 id="f5b097b1-aa22-4f58-8a9e-c1431d505fa5" class="">
                    Advertising Partners Privacy Policies
                </h2>
                <p id="0403daf4-35e2-428f-99bc-5b96d615bf8a" class="">
                    You may consult this list to find the Privacy Policy for
                    each of the advertising partners of Tone Notebook.
                </p>
                <p id="57b37137-71c6-44eb-82a9-819c28c77279" class="">
                    Third-party ad servers or ad networks uses technologies like
                    cookies, JavaScript, or Web Beacons that are used in their
                    respective advertisements and links that appear on Tone
                    Notebook, which are sent directly to users&#x27; browser.
                    They automatically receive your IP address when this occurs.
                    These technologies are used to measure the effectiveness of
                    their advertising campaigns and/or to personalize the
                    advertising content that you see on websites that you visit.
                </p>
                <p id="a979a02a-cb8e-4870-a33c-a659f126521a" class="">
                    Note that Tone Notebook has no access to or control over
                    these cookies that are used by third-party advertisers.
                </p>
                <h2 id="42b2975a-c9ea-413d-b9ae-dff0682bc4ac" class="">
                    Third Party Privacy Policies
                </h2>
                <p id="9cb1a92b-41a2-4a54-8c2a-4926c73c6939" class="">
                    Tone Notebook&#x27;s Privacy Policy does not apply to other
                    advertisers or websites. Thus, we are advising you to
                    consult the respective Privacy Policies of these third-party
                    ad servers for more detailed information. It may include
                    their practices and instructions about how to opt-out of
                    certain options.
                </p>
                <p id="f7990b52-b8c9-4d67-97b9-5c2f49ac72ed" class="">
                    You can choose to disable cookies through your individual
                    browser options. To know more detailed information about
                    cookie management with specific web browsers, it can be
                    found at the browsers&#x27; respective websites.
                </p>
                <h2 id="fe0e24ed-eaf3-4f36-92aa-53a1bdd29e67" class="">
                    CCPA Privacy Rights (Do Not Sell My Personal Information)
                </h2>
                <p id="b9069ff8-5be6-4699-9a96-73747c3b5c3e" class="">
                    Under the CCPA, among other rights, California consumers
                    have the right to:
                </p>
                <p id="5baa27ad-105b-4a3d-b580-c0dbd2737b95" class="">
                    Request that a business that collects a consumer&#x27;s
                    personal data disclose the categories and specific pieces of
                    personal data that a business has collected about consumers.
                </p>
                <p id="3915701c-b00d-4d2b-93b6-825f6f04795a" class="">
                    Request that a business delete any personal data about the
                    consumer that a business has collected.
                </p>
                <p id="cecfa126-84d4-44c0-bbf7-5751f32af395" class="">
                    Request that a business that sells a consumer&#x27;s
                    personal data, not sell the consumer&#x27;s personal data.
                </p>
                <p id="18167f32-715f-4a0c-95dd-d9dca668bc84" class="">
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                </p>
                <h2 id="e01cb86d-221c-452b-b122-6b9a4ab295e6" class="">
                    GDPR Data Protection Rights
                </h2>
                <p id="87c66290-d843-46f5-8ff1-4179f684e937" class="">
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following:
                </p>
                <p id="04bae121-85f0-4ea8-a357-ee28eaf948d3" class="">
                    The right to access – You have the right to request copies
                    of your personal data. We may charge you a small fee for
                    this service.
                </p>
                <p id="ea97e5b1-66bf-4133-9137-6592385ef9aa" class="">
                    The right to rectification – You have the right to request
                    that we correct any information you believe is inaccurate.
                    You also have the right to request that we complete the
                    information you believe is incomplete.
                </p>
                <p id="19857015-d336-4a46-9991-8f5dbe7e79e9" class="">
                    The right to erasure – You have the right to request that we
                    erase your personal data, under certain conditions.
                </p>
                <p id="353c4233-a428-4e5b-9efb-15b8ea5f9b59" class="">
                    The right to restrict processing – You have the right to
                    request that we restrict the processing of your personal
                    data, under certain conditions.
                </p>
                <p id="a0c8ed08-4763-44dd-861a-5217c212bdad" class="">
                    The right to object to processing – You have the right to
                    object to our processing of your personal data, under
                    certain conditions.
                </p>
                <p id="98633ace-f64c-467a-a6cf-e5f31108a697" class="">
                    The right to data portability – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                </p>
                <p id="3c678927-9213-460f-b16a-c1105785bd41" class="">
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us.
                </p>
                <h2 id="cc22b6e2-5296-498a-ad6a-26952e25b3fc" class="">
                    Children&#x27;s Information
                </h2>
                <p id="226bd06a-dad2-4a3a-a626-28476c4acbec" class="">
                    Another part of our priority is adding protection for
                    children while using the internet. We encourage parents and
                    guardians to observe, participate in, and/or monitor and
                    guide their online activity.
                </p>
                <p id="ff3d6cc5-43ab-4c58-a298-4dd8c3af5cfd" class="">
                    Tone Notebook does not knowingly collect any Personal
                    Identifiable Information from children under the age of 13.
                    If you think that your child provided this kind of
                    information on our website, we strongly encourage you to
                    contact us immediately and we will do our best efforts to
                    promptly remove such information from our records.
                </p>
            </div>
        );
    }
}

export default PrivacyPolicyPage;
