import React from "react";
import { withRouter, Link } from "react-router-dom";
import { firestore as db, auth } from "../../firebase/firebase.utils";
import axiosConfig from "../../axios/axios";
// import { Spinner } from "../../components/spinner/spinner.component";

import { connect } from "react-redux";
import { selectAuth } from "../../redux/user/user.slice";
import Spinner from "../../components/spinner/spinner.component";

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allPresets: [],
            loading: true,

            userDoc: {
                username: "",
                description: "",
                socialMediaLink: "",
                displayName: "",
                authType: ""
            },
            editingUsername: false,
            usernameIsValidating: false,
            usernameIsValid: false,
            usernameMessage: "",

            editingUserInfo: false,
            updatingUserInfo: false
        };
    }
    componentDidMount() {
        if (auth.currentUser) {
            this.getUser();
        }
    }

    componentDidUpdate(prevProps) {
        // console.log(auth.currentUser);
        if (
            auth.currentUser &&
            prevProps.authState.user.uid !== this.props.authState.user.uid
        ) {
            this.getUser();

            const user = auth.currentUser;
            if (user != null) {
                user.providerData.forEach((profile) => {
                    console.log("Sign-in provider: " + profile.providerId);
                });
            }
        } else if (!auth.currentUser) {
            this.props.history.push("/");
        }
    }

    // toggleEditing = async (e) => {
    //     e.preventDefault();
    //     const { editing } = this.state;
    //     if (editing) {
    //         await this.getUser();
    //     }
    //     this.setState({editing: !editing});
    // }

    toggleEditingUserInfo = async (e) => {
        if (e) e.preventDefault();
        const { editingUserInfo } = this.state;
        if (editingUserInfo) {
            await this.getUser();
        }
        this.setState({ editingUserInfo: !editingUserInfo });
    };

    handleUsernameChange = (e) => {
        const userDoc = this.state.userDoc;
        userDoc.username = e.target.value;
        this.setState({ userDoc: userDoc });
    };
    handleUserInfoChange = (e) => {
        const userDoc = this.state.userDoc;
        userDoc[e.target.name] = e.target.value;
        this.setState({ userDoc: userDoc });
    };

    updateUsername = async (e) => {
        e.preventDefault();
        try {
            const authToken = await auth.currentUser.getIdToken();

            const bodyParams = {
                username: this.state.userDoc.username
            };
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };

            const res = await axiosConfig.put(
                `/users/${this.props.user.uid}/username`,
                bodyParams,
                config
            );
            if (res.data.success) {
                console.log("Username updated");
                this.setState({ editing: false }, () => {
                    window.location.reload();
                });
            } else {
                console.log(res.data.message);
            }
        } catch (err) {
            console.log(err.response);
        }
    };

    updateUserInfo = async (e) => {
        e.preventDefault();
        this.setState({ updatingUserInfo: true }, async () => {
            try {
                const authToken = await auth.currentUser.getIdToken();
                const bodyParams = {
                    description: this.state.userDoc.description,
                    socialMediaLink: this.state.userDoc.socialMediaLink,
                    displayName: this.state.userDoc.displayName
                };
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                };
                await axiosConfig.put(
                    `/users/${this.props.user.uid}`,
                    bodyParams,
                    config
                );
                await this.getUser();
                this.toggleEditingUserInfo();
            } catch (err) {
                console.log(err);
            }
            this.setState({ updatingUserInfo: false });
        });
    };

    getUser = async () => {
        // console.log(this.state.userId);
        // var user = auth.currentUser;
        this.setState({ loading: true });
        try {
            const user = await db
                .collection("users")
                .doc(auth.currentUser.uid)
                .get();
            if (user.exists) {
                const userData = user.data();
                // this.setState({userDoc: userData});

                const allPresets = [];
                if (userData.presets)
                    for (const presetId of userData.presets) {
                        const preset = await db
                            .collection("tone_profiles_db")
                            .doc(presetId)
                            .get();
                        if (preset.exists) {
                            const presetData = preset.data();
                            allPresets.push({
                                name: presetData.name,
                                downloads: presetData.downloads,
                                id: presetId
                            });
                        }
                    }
                this.setState({
                    userDoc: userData,
                    allPresets: allPresets,
                    loading: false
                });
                return userData;
            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false });
        }
        return null;
    };

    validateUsername = async (username) => {
        try {
            const bodyParams = {
                username: username
            };
            const res = await axiosConfig.post(
                "/check-username-availability",
                bodyParams
            );
            if (res) {
                this.setState({
                    usernameIsValid: res.data.success,
                    usernameMessage: res.data.message,
                    usernameIsValidating: false
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    userNameOnChange = async (e) => {
        try {
            if (this.clearUsernameTimeout)
                clearTimeout(this.clearUsernameTimeout);
            const userDoc = this.state.userDoc;
            userDoc.username = e.target.value;
            this.setState({ userDoc: userDoc, usernameIsValidating: true });
            this.clearUsernameTimeout = setTimeout(
                () => this.validateUsername(userDoc.username),
                1500
            );
        } catch (e) {
            console.log(e);
        }
    };

    cancelEditingUserName = () => {
        this.setState(
            {
                editingUsername: false,
                usernameIsValidating: false,
                usernameMessage: ""
            },
            this.getUser
        );
    };

    render() {
        const message =
            this.state.userDoc?.username === "new_user"
                ? `Hey ${this.state.userDoc.displayName}, please quickly choose a unique username before your favorite one is taken.`
                : "";

        if (this.state.loading) {
            return (
                <div className="my-5 py-5 container text-center">
                    <Spinner />
                </div>
            );
        }
        // console.log(this.state.userDoc?.username?.length);
        return (
            <div className="container mt-5 pt-3">
                <h1 className="my-3">Edit user profile</h1>
                <hr />
                <div className="my-3">
                    <form onSubmit={this.updateUsername} autoComplete="off">
                        {message && (
                            <div className="text-danger mb-2">{message}</div>
                        )}
                        <label htmlFor="username" className="form-label">
                            Unique Username
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            placeholder="username"
                            value={this.state.userDoc.username}
                            onChange={this.userNameOnChange}
                            required
                            readOnly={!this.state.editingUsername}
                        />
                        {this.state.editingUsername ? (
                            <div className="mt-2">
                                {this.state.usernameIsValidating ? (
                                    <Spinner className="spinner-border-sm" />
                                ) : (
                                    <div
                                        className={
                                            this.state.usernameIsValid
                                                ? "text-success"
                                                : "text-danger"
                                        }
                                    >
                                        {this.state.usernameMessage}
                                    </div>
                                )}
                            </div>
                        ) : null}

                        {this.state.editingUsername ? (
                            <div className="d-flex">
                                <button
                                    type="submit"
                                    className="btn btn-primary my-2"
                                    disabled={
                                        !this.state.usernameIsValid ||
                                        this.state.usernameIsValidating
                                    }
                                    data-tag-this="button_click"
                                    data-tag-details="Save and refresh after updating username"
                                >
                                    Save & refresh
                                </button>
                                <button
                                    className="btn btn-secondary my-2 ms-2"
                                    onClick={this.cancelEditingUserName}
                                    data-tag-this="button_click"
                                    data-tag-details="Cancel updating username"
                                >
                                    Cancel
                                </button>
                            </div>
                        ) : (
                            <button
                                className="btn btn-primary my-2"
                                onClick={() =>
                                    this.setState({ editingUsername: true })
                                }
                                data-tag-this="button_click"
                                data-tag-details="Click to edit username"
                            >
                                Edit username
                            </button>
                        )}
                    </form>
                </div>
                <hr />

                <div className="my-3">
                    <h2>Public profile informatoin</h2>
                    <form onSubmit={this.updateUserInfo}>
                        <div className="my-3">
                            <label className="form-label">Display Name</label>
                            <textarea
                                type="text"
                                name="displayName"
                                className="form-control"
                                onChange={this.handleUserInfoChange}
                                value={this.state.userDoc.displayName}
                                readOnly={!this.state.editingUserInfo}
                            ></textarea>
                        </div>
                        <div className="my-3">
                            <label className="form-label">Description</label>
                            <textarea
                                type="text"
                                name="description"
                                className="form-control"
                                onChange={this.handleUserInfoChange}
                                value={this.state.userDoc.description}
                                readOnly={!this.state.editingUserInfo}
                            ></textarea>
                        </div>
                        <div className="my-3">
                            <label className="form-label">
                                Link to social media
                            </label>
                            <input
                                name="socialMediaLink"
                                type="text"
                                className="form-control"
                                onChange={this.handleUserInfoChange}
                                value={this.state.userDoc.socialMediaLink}
                                readOnly={!this.state.editingUserInfo}
                            ></input>
                        </div>
                        {this.state.editingUserInfo ? (
                            <div>
                                <button
                                    onClick={this.toggleEditingUserInfo}
                                    className="btn btn-danger-outline me-3"
                                    data-tag-this="button_click"
                                    data-tag-details="Discard user information changes"
                                >
                                    Discard Changes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary me-3"
                                    disabled={this.state.updatingUserInfo}
                                    data-tag-this="button_click"
                                    data-tag-details="Save user information"
                                >
                                    {this.state.updatingUserInfo ? (
                                        <Spinner className="spinner-border-sm" />
                                    ) : (
                                        "Save"
                                    )}
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={this.toggleEditingUserInfo}
                                className="btn btn-primary"
                                data-tag-this="button_click"
                                data-tag-details="Click to edit user profile information"
                            >
                                Edit
                            </button>
                        )}
                    </form>
                </div>
                <hr />
                <div className="my-3">
                    <h2>Security</h2>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            auth.sendPasswordResetEmail(
                                this.state.userDoc.email
                            );
                        }}
                        data-tag-this="button_click"
                        data-tag-details="Reset password button"
                    >
                        Reset password
                    </button>
                    <div>
                        <strong>Authentication method: </strong>
                        <div>{this.state.userDoc.authType}</div>
                    </div>
                </div>
                <hr />
                <div className="my-3">
                    <h2>Presets</h2>
                    {this.state.allPresets.map((preset) => {
                        return (
                            <Link key={preset.id} to={`/presets/${preset.id}`}>
                                {preset.name}{" "}
                            </Link>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(UserPage));
