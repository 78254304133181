import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyA2_GRADHBWgX86eqwioL05IUi6cW9jKR8",
    authDomain: "tonenotebookco.firebaseapp.com",
    projectId: "tonenotebookco",
    storageBucket: "tonenotebookco.appspot.com",
    messagingSenderId: "599897084295",
    appId: "1:599897084295:web:d2e56b03c95094afb54322",
    measurementId: "G-NMYM5JNX2X"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
export default firebase;

// Initialize Firestore
const firestore = firebase.firestore();
firestore.settings({
    timestampsInSnapshots: true,
    merge: true
});
if (window.location.hostname === "localhost") {
    console.log("Using firestore emulator");
    firestore.useEmulator("localhost", 8080);
}

// Firebase authentication
const auth = firebase.auth();
if (window.location.hostname === "localhost") {
    console.log("Using firebase auth emulator");
    auth.useEmulator("http://localhost:9099");
}

export { firestore, auth };

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = async () => {
    const result = await firebase.auth().signInWithPopup(provider);
    return result;
};

const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const signInWithFacebook = async () => {
    const result = await firebase.auth().signInWithPopup(facebookProvider);
    return result;
};

// export const createUserWithEmailAndPassowrd = async (emailInput, password, displayName) => {
//     try {
//         const result = await auth.createUserWithEmailAndPassword(emailInput, password);
//         // Add user to database
//         const { uid, email } = result.user;
//         await firestore.collection("users").doc(uid).set({
//             displayName: displayName,
//             email: email,
//             uid: uid
//         });
//         return result;
//     } catch (err) {
//         console.log(err);
//     }
// };

// Create a new user profile if the user is new
export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;

    const userRef = firestore.collection("users").doc(userAuth.uid);

    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        // const { email } = userAuth;
        const { uid, email } = userAuth;
        const createdAt = firebase.firestore.Timestamp.now();
        const userProfile = {
            // uid,
            newUser: true,
            username: uid,
            email,
            createdAt,
            description: "",
            socialMediaLink: "",
            ...additionalData
        };
        try {
            await userRef.set(userProfile);
            return userProfile;
        } catch (error) {
            console.log("Error creating user: ", error.message);
        }
    }
};
