import ReactTagInput from "@pathofdev/react-tag-input";
import React from "react";

function TagsInput(props) {
    if (!props.tags && !props.setTags)
        throw new Error("tags and setState are required props");

    const updateTags = (newTags) => {
        // Only sanitie tags when there is a new input
        if (newTags.length > props.tags.length) {
            // Get the newest input
            let lastItem = newTags.pop();
            // console.log(lastItem);

            // Only keep numbers and letters, convert to lower cases
            lastItem = lastItem
                .trim()
                .replace(/[^0-9a-zA-Z\s]+/g, "")
                .toLowerCase();

            // Add the newest input back
            if (lastItem) newTags.push(lastItem);

            // Update the parent state
            props.setTags(newTags);
        } else {
            props.setTags(newTags);
        }
    };
    return (
        <ReactTagInput
            tags={props.tags}
            onChange={updateTags}
            validator={(item) => {
                const isValid = item.length < 32;
                if (!isValid)
                    alert("Each tag must have less than 32 characters.");
                return isValid;
            }}
        />
    );
}

export default TagsInput;
