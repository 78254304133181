import React from "react";

export default function Spinner(props) {
    return (
        <div
            className={`spinner-grow ${props.className && props.className}`}
            role="status"
        >
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}
