import React from "react";
import TextareaAutosize from "react-textarea-autosize";

class InputTextAreaAutosize extends React.Component {
    render() {
        return (
            <div className="my-2">
                <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                >
                    {this.props.label}
                </label>
                <TextareaAutosize
                    className="form-control"
                    minRows={5}
                    {...this.props}
                />
            </div>
        );
    }
}

export default InputTextAreaAutosize;
