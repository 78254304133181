import React from "react";
import { withRouter, Link } from "react-router-dom";
import { auth } from "../../firebase/firebase.utils";

import PresetViewer from "../../components/preset-viewer/preset-viewer.component";
import CommentSection from "../../components/comment-section/comment-section.component";
import Tags from "../../components/tags/tags.component";
// import Spinner from "../../components/spinner/spinner.component";
import SchemaViewer from "../../components/schema-viewer/schema-viewer.component";

import { selectAuth } from "../../redux/user/user.slice";
import { connect } from "react-redux";
import axiosConfig from "../../axios/axios";

import RatingSection from "../../components/rating-section/rating-section.component";

import {
    APP_NAME,
    PRESET_TYPES,
    VISIBILITY_OPTIONS_JSON
} from "../../utils/constants.utils";
import Skeleton from "react-loading-skeleton";
// import 'react-loading-skeleton/dist/skeleton.css';

const PresetSkeletonLoader = (props) => {
    return (
        <div>
            <h2>
                <Skeleton />
            </h2>
            <h1>
                <Skeleton />
            </h1>
            <p>
                <Skeleton count={5} />
            </p>
        </div>
    );
};

class PresetsGetPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            presetId: this.props.match.params.presetId,
            presetDoc: null,
            presetType: null,
            comment: "",
            rating: 0,
            impressions: [],
            loading: true,
            presetDocIsLoaded: false,
            loginRequired: false
        };
        // this.loginRequired = false;
    }

    componentDidMount = (props) => {
        this.getPreset();
        document.title = APP_NAME + " | View";
    };

    componentDidUpdate = (prevProps) => {
        // if user just signed in, then try getting the preset again for private presets and like/save status
        if (
            !prevProps.authState.isSignedIn &&
            this.props.authState.isSignedIn
        ) {
            this.getPreset();
        }
    };

    getPreset = async () => {
        try {
            const presetId = this.state.presetId;
            let result;
            if (this.props.authState.isSignedIn) {
                const authToken = await auth.currentUser.getIdToken();
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                };
                result = await axiosConfig.get(`/presets/${presetId}`, config);
            } else {
                result = await axiosConfig.get(`/presets/${presetId}`);
            }

            if (result.data.info === "LOGIN_REQUIRED") {
                this.setState({ loginRequired: true });
            } else {
                const { presetType } = result.data;
                this.setState({
                    presetDoc: result.data,
                    presetType,
                    loading: false,
                    presetDocIsLoaded: true
                });
            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false, presetDocIsLoaded: true });
            // alert("Something went wrong.");
        }
    };

    submitSchema = async () => {
        const confirm = window.confirm(
            "Are you sure you want to make this preset a schema? This cannot be undone."
        );
        if (!confirm) return;
        try {
            if (this.state.presetType === PRESET_TYPES.thrii) {
                throw new Error(
                    "This preset is THRii preset, cannot be uploaded as a schema."
                );
            }
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            const data = {
                brand: this.state.presetDoc.schema.brand,
                model: this.state.presetDoc.schema.model,
                attributes: this.state.presetDoc.schema.attributes
            };
            await axiosConfig.post(`/schemas`, data, config);
        } catch (err) {
            alert("Schema submission failed, see log for details.");
            console.log(err);
        }
    };

    deletePreset = async () => {
        const confirm = window.confirm(
            "Are you sure you want to delete this preset? This cannot be undone."
        );
        if (!confirm) return;
        try {
            const presetId = this.state.presetId;
            const authToken = await auth.currentUser.getIdToken();
            const config = {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            };
            await axiosConfig.delete(`/presets/${presetId}`, config);
            this.props.history.push("/presets");
        } catch (err) {
            console.log(err);
            alert("Something went wrong.");
        }
    };

    addDownload = async () => {
        try {
            axiosConfig.post(
                `presets/${this.state.presetId}/increment-downloads`
            );
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="container page-wrapper text-center">
                    {/* <Spinner/>  */}
                    <PresetSkeletonLoader />
                    {this.state.loginRequired ? (
                        <div className="my-2">
                            <strong>
                                This is a private preset, only the authenticated
                                owner can view this preset.{" "}
                            </strong>{" "}
                            <br />
                            Awaiting automatic sign in. If you are not signed in
                            automatically,{" "}
                            <Link to="/login"> please click here sign in</Link>.
                        </div>
                    ) : (
                        <div className="mx-3">Loading...</div>
                    )}
                </div>
            );
        } else if (!this.state.presetDoc)
            return (
                <div className="container my-5 pt-5">
                    <h1 className="mb-0">Not found</h1>
                    <p>
                        This preset is not available, it might have been deleted
                        by the owner.
                    </p>
                </div>
            );

        let brand = "",
            model = "";
        // let type = "";

        if (this.state.presetDoc.presetType === PRESET_TYPES.thrii) {
            brand = "Yamaha";
            model = "THR-ii";
            // type = "Downloadable Preset File";
        } else if (this.state.presetDoc.presetType === PRESET_TYPES.schema) {
            brand = this.state.presetDoc.schema.brand;
            model = this.state.presetDoc.schema.model;
            // let isCustom = this.state.presetDoc.schema.isCustom;
            // if (isCustom) type = "Custom Schema";
            // else type = "ToneNotebook SSchema";
        }

        const { user } = this.props.authState;
        return (
            <div className="page-wrapper">
                <div className="">
                    <div className="container pt-3">
                        <div className="d-md-flex justify-content-between flex-wrap">
                            <div className="">
                                <Tags
                                    maxTags="10"
                                    tags={this.state.presetDoc.tags}
                                />
                            </div>
                            <div className="text-large-175 order-md-first">
                                {brand} - {model}
                                {/* <span className="opacity-25">- {type}</span> */}
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <h1 className="my-1">
                                <strong> {this.state.presetDoc.name}</strong>
                            </h1>
                        </div>

                        <div className="d-flex justify-content-between align-items-end">
                            <div>
                                <label className="mb-3 d-md-flex justify-content-start ">
                                    <div
                                        data-tag-this="link_click"
                                        data-tag-details="Go to preset author from preset view page"
                                    >
                                        <span className="me-1">
                                            Uploaded by:{" "}
                                        </span>
                                        <Link
                                            to={`/user/${this.state.presetDoc.ownerId}`}
                                        >
                                            {this.state.presetDoc.ownerUsername}
                                        </Link>
                                    </div>
                                    <div>
                                        <span className="d-none d-md-inline mx-2 opacity-50">
                                            |
                                        </span>
                                        <span className="opacity-50">
                                            Last updated:{" "}
                                            {new Date(
                                                this.state.presetDoc.lastUpdated
                                            ).toDateString()}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="d-none d-md-inline mx-2 opacity-50">
                                            |
                                        </span>
                                        <span className="mt-1 opacity-50">
                                            {this.state.presetDoc.visibility ===
                                                VISIBILITY_OPTIONS_JSON.private && (
                                                <span>
                                                    <i className="fa-solid fa-lock"></i>{" "}
                                                    Private
                                                </span>
                                            )}
                                            {this.state.presetDoc.visibility ===
                                                VISIBILITY_OPTIONS_JSON.public && (
                                                <span>
                                                    <i className="fa-solid fa-people-group"></i>{" "}
                                                    Public
                                                </span>
                                            )}
                                            {this.state.presetDoc.visibility ===
                                                VISIBILITY_OPTIONS_JSON.unlisted && (
                                                <span>
                                                    <i className="fa-solid fa-eye-slash"></i>{" "}
                                                    Unlisted
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </label>
                                {this.state.presetDoc.description && (
                                    <p
                                        className="mt-3 mb-4 py-2"
                                        style={{ maxWidth: 900 }}
                                    >
                                        {this.state.presetDoc.description}
                                    </p>
                                )}
                                {/* Below is buttons, if there are buttons, have margin on top */}
                                <div
                                    className={`${
                                        user.uid ===
                                            this.state.presetDoc.ownerId ||
                                        this.state.presetType ===
                                            PRESET_TYPES.thrii
                                            ? "mt-3"
                                            : ""
                                    }`}
                                >
                                    {/* Only owner can see the following seciton */}
                                    {user.uid ===
                                        this.state.presetDoc.ownerId && (
                                        <div className="d-inline">
                                            <button
                                                className="btn btn-danger me-3 mb-1"
                                                onClick={this.deletePreset}
                                                data-tag-this="button_click"
                                                data-tag-details="Delete preset"
                                            >
                                                <i className="far fa-trash-alt"></i>
                                                <span className="ms-2">
                                                    Delete
                                                </span>
                                            </button>
                                            <button
                                                className="btn btn-secondary me-3 mb-1"
                                                onClick={() =>
                                                    this.props.history.push(
                                                        `/presets/${this.state.presetId}/update`
                                                    )
                                                }
                                                data-tag-this="button_click"
                                                data-tag-details="Edit preset info"
                                            >
                                                <i className="fas fa-pencil-alt"></i>
                                                <span className="ms-2">
                                                    Edit Info
                                                </span>
                                            </button>
                                            {this.state.presetType ===
                                                PRESET_TYPES.schema && (
                                                <button
                                                    className="btn btn-secondary me-3 mb-1"
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            `/presets/${this.state.presetId}/update-schema`
                                                        )
                                                    }
                                                    data-tag-this="button_click"
                                                    data-tag-details="Edit preset schema"
                                                >
                                                    <i className="fa-solid fa-gears"></i>
                                                    <span className="ms-2">
                                                        Edit Schema
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    )}
                                    {/* Admin section */}
                                    {user.isAdmin && (
                                        <div className="d-inline">
                                            <h3>Admin interface</h3>
                                            <button
                                                onClick={this.submitSchema}
                                                className="btn btn-warning"
                                                data-tag-this="button_click"
                                                data-tag-details="Make this admin schema"
                                            >
                                                Make this schema
                                            </button>
                                        </div>
                                    )}
                                    {this.state.presetType ===
                                        PRESET_TYPES.thrii && (
                                        <a
                                            href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                JSON.stringify(
                                                    this.state.presetDoc.thrl6p
                                                )
                                            )}`}
                                            download={`${this.state.presetDoc.name}.thrl6p`}
                                            onClick={this.addDownload}
                                        >
                                            <button
                                                className="btn btn-primary mb-1"
                                                data-tag-this="button_click"
                                                data-tag-details="Download preset by clicking on 'Download Preset' button"
                                            >
                                                <span className="me-2">
                                                    Download THR-ii Preset File{" "}
                                                </span>
                                                <span className="">
                                                    <i className="fas fa-arrow-down"></i>
                                                    <span className="ms-1">
                                                        {
                                                            this.state.presetDoc
                                                                .downloads
                                                        }
                                                    </span>
                                                </span>
                                            </button>
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className="">
                                <RatingSection
                                    presetId={this.state.presetId}
                                    numOfRatings={
                                        this.state.presetDoc.numOfRatings
                                    }
                                    likes={this.state.presetDoc.likes}
                                    dislikes={this.state.presetDoc.dislikes}
                                    score={this.state.presetDoc.score}
                                    downloads={this.state.presetDoc.downloads}
                                    presetDoc={this.state.presetDoc}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="container my-5 ">
                    {this.state.presetType === PRESET_TYPES.schema && (
                        <SchemaViewer schema={this.state.presetDoc.schema} />
                    )}
                    {this.state.presetType === PRESET_TYPES.thrii && (
                        <PresetViewer preset={this.state.presetDoc.thrl6p} />
                    )}
                    {true && <CommentSection presetId={this.state.presetId} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { authState: selectAuth(state) };
};

export default connect(mapStateToProps)(withRouter(PresetsGetPage));
