import React from "react";
import { withRouter } from "react-router-dom";
import axiosConfig from "../../axios/axios";
import { firestore as db } from "../../firebase/firebase.utils";
import PresetList from "../../components/presets-list/presets-list.component";
import Spinner from "../../components/spinner/spinner.component";

class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.userId = this.props.match.params.userId;
        this.state = {
            userDoc: null,
            loaded: false,
            presets: []
        };
    }
    componentDidMount() {
        this.getUserPublicInfo();
        this.getUserPresets();
    }

    getUserPublicInfo = async () => {
        try {
            const res = await axiosConfig.get(`/users/${this.userId}`);
            this.setState({ userDoc: res.data.user, loaded: true });
        } catch (err) {
            console.log(err);
            this.setState({ loaded: true });
        }
    };

    getUserPresets = async () => {
        try {
            const presets = await db
                .collection("tone_profiles_db")
                .where("ownerId", "==", this.userId)
                .where("visibility", "==", "PUBLIC")
                .get();
            if (presets.empty) return console.log("No matching presets");

            const allPresets = [];
            presets.forEach((doc) => {
                allPresets.push({ presetId: doc.id, ...doc.data() });
            });
            this.setState({ presets: allPresets });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        return (
            <div className="container page-wrapper">
                <div style={{ maxWidth: 900 }} className="my-3 mx-auto">
                    {this.state.loaded && this.state.userDoc && (
                        <div>
                            <div className="text-center">
                                <img
                                    style={{ maxWidth: 180 }}
                                    className="w-100 mx-auto"
                                    src={`https://robohash.org/${this.userId}?size=240x240`}
                                    alt="profile"
                                />
                                <h1 className=" text-700 mb-0 mt-3">
                                    {this.state.userDoc.displayName}
                                </h1>
                                <div className="text-large ">
                                    {" "}
                                    @{this.state.userDoc.username}{" "}
                                </div>
                            </div>
                            <div className="my-3">
                                <h3>Description</h3>
                                <div>
                                    {this.state.userDoc.description || "--"}
                                </div>
                            </div>
                            <div className="my-3">
                                <h3>Social Media Link</h3>
                                <div>
                                    {this.state.userDoc.socialMediaLink ? (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={
                                                this.state.userDoc
                                                    .socialMediaLink
                                            }
                                        >
                                            {" "}
                                            {this.state.userDoc.socialMediaLink}
                                        </a>
                                    ) : (
                                        <div>--</div>
                                    )}
                                </div>
                            </div>
                            <div className="my-3">
                                <h3>User uploads</h3>
                                {(this.state.presets.length && (
                                    <PresetList presets={this.state.presets} />
                                )) ||
                                    "User doesn't have any upload."}
                            </div>
                        </div>
                    )}

                    {!this.state.loaded && (
                        <div className="text-center">
                            <Spinner />
                        </div>
                    )}

                    {this.state.loaded && !this.state.userDoc && (
                        <div>
                            <h1>User not found</h1>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(UserPage);
