import ReactTagInput from "@pathofdev/react-tag-input";
import React from "react";

function InputTags(props) {
    if (!props.tags && !props.setTags)
        throw new Error("tags and setTags are required props");
    const updateTags = (tags) => {
        props.setTags(tags);
    };
    return (
        <div className="my-2">
            {props.label ? <label>{props.label}</label> : null}
            <ReactTagInput
                tags={props.tags}
                onChange={updateTags}
                validator={(item) => {
                    const isValid = item.length < 32;
                    if (!isValid)
                        alert("Each tag must have less than 32 characters.");
                    return isValid;
                }}
            />
        </div>
    );
}

export default InputTags;
